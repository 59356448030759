/* -----------------------------------------
   Template helpers
----------------------------------------- */
/* ------------------ Sizes --------------------*/
.s8 {
  font-size: 8px !important;
}

.s10 {
  font-size: 10px !important;
}

.s12 {
  font-size: 12px !important;
}

.s14 {
  font-size: 14px !important;
}

.s16 {
  font-size: 16px !important;
}

.s20 {
  font-size: 20px !important;
}

.s24 {
  font-size: 24px !important;
}

.s32 {
  font-size: 32px !important;
}

.s64 {
  font-size: 64px !important;
}
/* ------------------ Vertical align --------------------*/
.vat {
  vertical-align: top !important;
}

.vam {
  vertical-align: middle !important;
}

.vab {
  vertical-align: bottom !important;
}

.vertical-align {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
/* ------------------ Margins --------------------*/
.row.no-padding {
  margin-left: 0;
  margin-right: 0;
}

  .row.no-padding [class^="col-"] {
    padding: 0;
  }

.m0 {
  margin: 0 !important;
}

.m5 {
  margin: 5px !important;
}

.m10 {
  margin: 10px !important;
}

.m15 {
  margin: 15px !important;
}

.m20 {
  margin: 20px !important;
}

.m25 {
  margin: 25px !important;
}

.mt0 {
  margin-top: 0 !important;
}

.mt5 {
  margin-top: 5px !important;
}

.mt10 {
  margin-top: 10px !important;
}

.mt15 {
  margin-top: 15px !important;
}

.mt20 {
  margin-top: 20px !important;
}

.mt25 {
  margin-top: 25px !important;
}

.mt50 {
  margin-top: 50px !important;
}

.mr0 {
  margin-right: 0 !important;
}

.mr5 {
  margin-right: 5px !important;
}

.mr10 {
  margin-right: 10px !important;
}

.mr15 {
  margin-right: 15px !important;
}

.mr20 {
  margin-right: 20px !important;
}

.mr25 {
  margin-right: 25px !important;
}

.mb0 {
  margin-bottom: 0 !important;
}

.mb5 {
  margin-bottom: 5px !important;
}

.mb10 {
  margin-bottom: 10px !important;
}

.mb15 {
  margin-bottom: 15px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.mb25 {
  margin-bottom: 25px !important;
}

.mb50 {
  margin-bottom: 50px !important;
}

.ml0 {
  margin-left: 0 !important;
}

.ml5 {
  margin-left: 5px !important;
}

.ml10 {
  margin-left: 10px !important;
}

.ml15 {
  margin-left: 15px !important;
}

.ml20 {
  margin-left: 20px !important;
}

.ml25 {
  margin-left: 25px !important;
}
/* Minus margins */
.mlm5 {
  margin-top: -5px !important;
}

.mlm10 {
  margin-top: -10px !important;
}

.mlm15 {
  margin-top: -15px !important;
}

.mlm20 {
  margin-top: -20px !important;
}

.mlm25 {
  margin-top: -25px !important;
}

.mtm5 {
  margin-top: -5px !important;
}

.mtm10 {
  margin-top: -10px !important;
}

.mtm15 {
  margin-top: -15px !important;
}

.mtm20 {
  margin-top: -20px !important;
}

.mtm25 {
  margin-top: -25px !important;
}

.mrm5 {
  margin-top: -5px !important;
}

.mrm10 {
  margin-top: -10px !important;
}

.mrm15 {
  margin-top: -15px !important;
}

.mrm20 {
  margin-top: -20px !important;
}

.mrm25 {
  margin-top: -25px !important;
}

.mbm5 {
  margin-top: -5px !important;
}

.mbm10 {
  margin-top: -10px !important;
}

.mbm15 {
  margin-top: -15px !important;
}

.mbm20 {
  margin-top: -20px !important;
}

.mbm25 {
  margin-top: -25px !important;
}
/* ------------------ Paddings --------------------*/
.p0 {
  padding: 0 !important;
}

.p5 {
  padding: 5px !important;
}

.p10 {
  padding: 10px !important;
}

.p15 {
  padding: 15px !important;
}

.p20 {
  padding: 20px !important;
}

.p25 {
  padding: 25px !important;
}

.pt0 {
  padding-top: 0 !important;
}

.pt5 {
  padding-top: 5px !important;
}

.pt10 {
  padding-top: 10px !important;
}

.pt15 {
  padding-top: 15px !important;
}

.pt20 {
  padding-top: 20px !important;
}

.pt25 {
  padding-top: 25px !important;
}

.pr0 {
  padding-right: 0 !important;
}

.pr5 {
  padding-right: 5px !important;
}

.pr10 {
  padding-right: 10px !important;
}

.pr15 {
  padding-right: 15px !important;
}

.pr20 {
  padding-right: 20px !important;
}

.pr25 {
  padding-right: 25px !important;
}

.pb0 {
  padding-bottom: 0 !important;
}

.pb5 {
  padding-bottom: 5px !important;
}

.pb10 {
  padding-bottom: 10px !important;
}

.pb15 {
  padding-bottom: 15px !important;
}

.pb20 {
  padding-bottom: 20px !important;
}

.pb25 {
  padding-bottom: 25px !important;
}

.pl0 {
  padding-left: 0 !important;
}

.pl5 {
  padding-left: 5px !important;
}

.pl10 {
  padding-left: 10px !important;
}

.pl15 {
  padding-left: 15px !important;
}

.pl20 {
  padding-left: 20px !important;
}

.pl25 {
  padding-left: 25px !important;
}
/* ------------------ Percent widths --------------------*/
.per5 {
  width: 5% !important;
}

.per10 {
  width: 10% !important;
}

.per15 {
  width: 15% !important;
}

.per20 {
  width: 20% !important;
}

.per25 {
  width: 25% !important;
}

.per30 {
  width: 30% !important;
}

.per35 {
  width: 35% !important;
}

.per40 {
  width: 40% !important;
}

.per45 {
  width: 45% !important;
}

.per50 {
  width: 50% !important;
}

.per55 {
  width: 55% !important;
}

.per60 {
  width: 60% !important;
}

.per65 {
  width: 65% !important;
}

.per70 {
  width: 70% !important;
}

.per75 {
  width: 75% !important;
}

.per80 {
  width: 80% !important;
}

.per85 {
  width: 85% !important;
}

.per90 {
  width: 90% !important;
}

.per95 {
  width: 95% !important;
}

.per100 {
  width: 100% !important;
}
/* ------------------ Borders --------------------*/
.b {
  border: 1px solid #e3e3e3 !important;
}

.bt {
  border-top: 1px solid #e3e3e3 !important;
}

.br {
  border-right: 1px solid #e3e3e3 !important;
}

.bb {
  border-bottom: 1px solid #e3e3e3 !important;
}

.bl {
  border-left: 1px solid #e3e3e3 !important;
}

.btlr {
  border-top-left-radius: 3px !important;
}

.btrr {
  border-top-right-radius: 3px !important;
}

.bblr {
  border-bottom-left-radius: 3px !important;
}

.bbrr {
  border-bottom-right-radius: 3px !important;
}

.brall {
  border-radius: 3px !important;
}

.bn {
  border: none !important;
}

.bnt {
  border-top: none !important;
}

.bnb {
  border-bottom: none !important;
}

.bnl {
  border-left: none !important;
}

.bnr {
  border-right: none !important;
}

.brad0 {
  border-radius: 0 !important;
}

.btlr0 {
  border-top-left-radius: 0 !important;
}

.btrr0 {
  border-top-right-radius: 0 !important;
}

.bblr0 {
  border-bottom-left-radius: 0 !important;
}

.bbrr0 {
  border-bottom-right-radius: 0 !important;
}

.brad {
  border-radius: 3px !important;
}

.bbdashed {
  border-bottom: 1px dashed #e3e3e3 !important;
}
/* ------------------ Colors --------------------*/
.color-red {
  color: #db5565 !important;
}

.color-red-light {
  color: #df6a78 !important;
}

.color-red-dark {
  color: #c04b59 !important;
}

.color-blue {
  color: #0bacd3 !important;
}

.color-blue-light {
  color: #29b6d8 !important;
}

.color-blue-dark {
  color: #0a97b9 !important;
}

.color-green {
  color: #51bf87 !important;
}

.color-green-light {
  color: #66c796 !important;
}

.color-green-dark {
  color: #47a877 !important;
}

.color-yellow {
  color: #f4ad49 !important;
}

.color-yellow-light {
  color: #f5b75f !important;
}

.color-yellow-dark {
  color: #d69840 !important;
}

.color-white {
  color: #ffffff !important;
}

.color-dark {
  color: #262d37 !important;
}

.color-dark-light {
  color: #444548 !important;
}

.color-dark-dark {
  color: #1c2128 !important;
}

.color-gray {
  color: #555555 !important;
}

.color-gray-light {
  color: #777777 !important;
}

.color-gray-dark {
  color: #333333 !important;
}
/* ------------------ Backgrounds --------------------*/
.white-bg {
  color: #333333 !important;
  background-color: #ffffff !important;
}

.red-bg {
  color: #ffffff !important;
  background-color: #db5565 !important;
}

.red-light-bg {
  color: #ffffff !important;
  background-color: #df6a78 !important;
}

.red-dark-bg {
  color: #ffffff !important;
  background-color: #c04b59 !important;
}

.blue-bg {
  color: #ffffff !important;
  background-color: #0bacd3 !important;
}

.blue-light-bg {
  color: #ffffff !important;
  background-color: #29b6d8 !important;
}

.blue-dark-bg {
  color: #ffffff !important;
  background-color: #0a97b9 !important;
}

.green-bg {
  color: #ffffff !important;
  background-color: #51bf87 !important;
}

.green-light-bg {
  color: #ffffff !important;
  background-color: #66c796 !important;
}

.green-dark-bg {
  color: #ffffff !important;
  background-color: #47a877 !important;
}

.yellow-bg {
  color: #ffffff !important;
  background-color: #f4ad49 !important;
}

.yellow-light-bg {
  color: #ffffff !important;
  background-color: #f5b75f !important;
}

.yellow-dark-bg {
  color: #ffffff !important;
  background-color: #d69840 !important;
}

.gray-bg {
  color: #333333 !important;
  background-color: #555555 !important;
}

.gray-light-bg {
  color: #333333 !important;
  background-color: #777777 !important;
}

.gray-lighter-bg {
  color: #333333 !important;
  background-color: #eeeeee !important;
}

.gray-dark-bg {
  color: #333333 !important;
  background-color: #333333 !important;
}

.gray-darker-bg {
  color: #333333 !important;
  background-color: #222222 !important;
}

.dark-bg {
  color: #ffffff !important;
  background-color: #262d37 !important;
}

.dark-light-bg {
  color: #ffffff !important;
  background-color: #444548 !important;
}

.dark-dark-bg {
  color: #ffffff !important;
  background-color: #1c2128 !important;
}
/* ------------------ Opacity --------------------*/
.opacity {
  opacity: 1 !important;
}

.opacity0 {
  opacity: 0 !important;
}
/* ------------------ Typo --------------------*/
.strong {
  font-weight: bold;
}

.uppercase {
  text-transform: uppercase;
}

.normal {
  font-weight: normal;
}

.text-ellipsis {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/* ------------------ Contextual colors --------------------*/
.text-muted {
  color: #777777;
}

.text-primary {
  color: #0bacd3;
}

a.text-primary:hover,
a.text-primary:focus {
  color: #0884a3;
}

.text-success {
  color: #51bf87;
}

a.text-success:hover,
a.text-success:focus {
  color: #3ba26e;
}

.text-info {
  color: #29b6d8;
}

a.text-info:hover,
a.text-info:focus {
  color: #2093ae;
}

.text-warning {
  color: #f4ad49;
}

a.text-warning:hover,
a.text-warning:focus {
  color: #f19719;
}

.text-danger {
  color: #db5565;
}

a.text-danger:hover,
a.text-danger:focus {
  color: #d12c40;
}

.text-yellow {
  color: #f4ad49;
}

a.text-yellow:hover,
a.text-yellow:focus {
  color: #f19719;
}

.text-dark {
  color: #262d37;
}

a.text-dark:hover,
a.text-dark:focus {
  color: #111419;
}
/* ------------------ Positions --------------------*/
.relative {
  position: relative;
}

.absolute {
  position: absolute;
}
/* ------------------ Rotate helpers --------------------*/
.rotate0 {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

.rotate15 {
  -webkit-transform: rotate(15deg);
  -ms-transform: rotate(15deg);
  transform: rotate(15deg);
}

.rotate30 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}

.rotate45 {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.rotate60 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}

.rotate75 {
  -webkit-transform: rotate(75deg);
  -ms-transform: rotate(75deg);
  transform: rotate(75deg);
}

.rotate90 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.rotate105 {
  -webkit-transform: rotate(105deg);
  -ms-transform: rotate(105deg);
  transform: rotate(105deg);
}

.rotate120 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}

.rotate135 {
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
}

.rotate150 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}

.rotate165 {
  -webkit-transform: rotate(165deg);
  -ms-transform: rotate(165deg);
  transform: rotate(165deg);
}

.rotate180 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.rotateM15 {
  -webkit-transform: rotate(-15deg);
  -ms-transform: rotate(-15deg);
  transform: rotate(-15deg);
}

.rotateM30 {
  -webkit-transform: rotate(-30deg);
  -ms-transform: rotate(-30deg);
  transform: rotate(-30deg);
}

.rotateM45 {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.rotateM60 {
  -webkit-transform: rotate(-60deg);
  -ms-transform: rotate(-60deg);
  transform: rotate(-60deg);
}

.rotateM75 {
  -webkit-transform: rotate(-75deg);
  -ms-transform: rotate(-75deg);
  transform: rotate(-75deg);
}

.rotateM90 {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.rotateM105 {
  -webkit-transform: rotate(-105deg);
  -ms-transform: rotate(-105deg);
  transform: rotate(-105deg);
}

.rotateM120 {
  -webkit-transform: rotate(-120deg);
  -ms-transform: rotate(-120deg);
  transform: rotate(-120deg);
}

.rotateM135 {
  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.rotateM150 {
  -webkit-transform: rotate(-150deg);
  -ms-transform: rotate(-150deg);
  transform: rotate(-150deg);
}

.rotateM165 {
  -webkit-transform: rotate(-165deg);
  -ms-transform: rotate(-165deg);
  transform: rotate(-165deg);
}

.rotateM180 {
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
/* ------------------ Other --------------------*/
.bshadowNone {
  box-shadow: none !important;
}

.di {
  display: inline !important;
}

.dib {
  display: inline-block !important;
}

.db {
  display: block !important;
}
/* -----------------------------------------
   Body styles
----------------------------------------- */
/*  IE10 and windows phone 8 fix. */
@-webkit-viewport {
  width: device-width;
}

@-moz-viewport {
  width: device-width;
}

@-ms-viewport {
  width: device-width;
}

@-o-viewport {
  width: device-width;
}

@viewport {
  width: device-width;
}

html,
body {
  height: 100%;
}

  html.ff3 {
    min-height: 100%;
  }

    html.ff3 body {
      min-height: 100%;
    }

body {
  overflow-x: hidden;
  background: #f2f2f2;
  color: #333333;
  -webkit-font-smoothing: antialiased;
  font-weight: 300;
  position: relative;
}

  body.ovh {
    overflow: hidden;
  }

  body.fixed-left-sidebar:not(.fixed-header) .page-navbar {
    padding-left: 195px;
  }

  body.fixed-left-sidebar:not(.fixed-header) .navbar-brand {
    position: fixed;
    width: 250px;
    top: 0;
    left: 0;
  }

  body.fixed-left-sidebar:not(.fixed-header) .navbar-collapse {
    padding-left: 250px;
  }

  body.fixed-right-sidebar:not(.fixed-header) #right-sidebar.rstop {
    padding-top: 0;
  }

* {
  box-sizing: border-box;
  outline: none !important;
}

#wrapper {
  height: auto;
  min-height: 100%;
  position: relative;
}
/* ------------------ Login page --------------------*/
.login-page > .login-container {
  width: 360px;
}

.login-page .login-panel {
  margin-top: 100px;
}

.login-page .user-avatar {
  text-align: center;
  background-color: #e8e8e8;
  padding: 15px 0;
  margin: 0 -15px 20px;
}

  .login-page .user-avatar img {
    border-radius: 50%;
  }

.error-page > .error-container {
  width: 480px;
}

.error-page .error-panel {
  margin-top: 100px;
}

.error-page .error-number {
  text-align: center;
  font-weight: bold;
  font-size: 148px;
  margin-top: 0;
}

.error-page .btn-group {
  width: 100%;
}

.error-page .btn {
  text-align: left;
}

  .error-page .btn i {
    margin-right: 5px;
  }
/* -----------------------------------------
   Header styles
----------------------------------------- */
.page-navbar {
  width: 100%;
  float: left;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 101;
  background-color: #ffffff;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.15);
}

  .page-navbar.header-fixed {
    position: fixed;
  }

  .page-navbar .navbar-brand {
    width: 250px;
    color: #4d4d4d;
    background-color: #5E2E86;
    text-transform: uppercase;
    font-weight: bold;
    padding: 1px 15px;
    font-size: 21px;
  }

    .page-navbar .navbar-brand.collapse-sidebar {
      width: 60px;
      background-color: #fff;
    }

      .page-navbar .navbar-brand.collapse-sidebar .logo {
        display: none;
      }

      .page-navbar .navbar-brand.collapse-sidebar .logo-sm {
        display: block !important;
        float: left;
        margin-left: 6px;
      }

    .page-navbar .navbar-brand.hide-logo {
      display: none;
    }

  .page-navbar .navbar-toggle {
    position: relative;
    float: right;
    padding: 9px 10px;
    margin-top: 8px;
    margin-right: 15px;
    margin-bottom: 8px;
    background-color: transparent;
    background-image: none;
    border: 1px solid #333333;
    border-radius: 4px;
  }

    .page-navbar .navbar-toggle .icon-bar {
      display: block;
      width: 22px;
      height: 2px;
      border-radius: 1px;
      background-color: #333333;
    }

  .page-navbar .navbar-no-collapse {
    width: auto;
    padding-right: 15px;
    padding-left: 15px;
  }

  .page-navbar .navbar-nav > li {
    border-radius: 0;
  }

    .page-navbar .navbar-nav > li > a {
      color: #333333;
      padding: 15px 21.5px;
      height: 50px;
      transition: background;
      transition-duration: 0.4s;
    }

      .page-navbar .navbar-nav > li > a i {
        font-size: 20px;
        float: left;
      }

        .page-navbar .navbar-nav > li > a i[class^="glyphicon"] {
          margin-top: -5px;
        }


      .page-navbar .navbar-nav > li > a .badge {
        padding: 3px 5px 2px;
        position: absolute;
        top: 8px;
        right: 12px;
      }

    .page-navbar .navbar-nav > li.open > a {
      background-color: #fff;
    }

    @media only screen and (max-width: 800px) {
      .page-navbar .navbar-nav > li.open > a {
        background-color: #5E2E86;
      }
    }

    .page-navbar .navbar-nav > li > a img.avatar {
      border-radius: 50%;
      border: 2px solid #eeeeee;
      width: 42px;
      height: 42px;
    }

    .page-navbar .navbar-nav > li .dropdown-menu {
      margin-top: 12px;
    }

      .page-navbar .navbar-nav > li .dropdown-menu > li {
        border-radius: 0;
      }

        .page-navbar .navbar-nav > li .dropdown-menu > li a {
          padding: 10px 20px;
        }

          .page-navbar .navbar-nav > li .dropdown-menu > li a.dropdown-menu-header {
            background: #f7f9fe;
            font-weight: bold;
            border-bottom: 1px solid #e3e3e3;
          }

          .page-navbar .navbar-nav > li .dropdown-menu > li a.view-all {
            text-align: center;
            border-top: 1px solid #e3e3e3;
            background: #f7f9fe;
          }

        .page-navbar .navbar-nav > li .dropdown-menu > li:last-child {
          border-bottom: none;
        }

        .page-navbar .navbar-nav > li .dropdown-menu > li span.txt {
          padding: 10px 20px;
          display: inline-block;
        }

      .page-navbar .navbar-nav > li .dropdown-menu:after {
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: #fff;
      }

      .page-navbar .navbar-nav > li .dropdown-menu:before {
        border-bottom-color: #e5ebfc;
      }

      .page-navbar .navbar-nav > li .dropdown-menu.dynamic-settings {
        min-width: 220px;
      }

        .page-navbar .navbar-nav > li .dropdown-menu.dynamic-settings .toggle-custom {
          padding: 5px 10px;
        }
/* -----------------------------------------
   Left Sidebar styles
----------------------------------------- */
.page-sidebar {
  width: 250px;
  position: absolute;
  height: auto;
  left: 0;
  bottom: 0;
  top: 0;
  padding-top: 50px;
  z-index: 100;
}

  .page-sidebar:after {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 250px;
    background-color: #55565A;
    z-index: -1;
  }

  .page-sidebar .sidebar-inner {
    position: relative;
    width: auto;
    height: 100%;
  }

  .page-sidebar.sidebar-fixed {
    position: fixed;
  }

  .page-sidebar.hide-sidebar {
    margin-left: -250px;
  }

    .page-sidebar.hide-sidebar:after {
      left: -250px;
    }

    .page-sidebar.hide-sidebar #search-menu {
      display: none;
    }

  .page-sidebar.collapse-sidebar {
    width: 60px;
    bottom: auto;
  }

    .page-sidebar.collapse-sidebar:after {
      width: 60px;
    }

    .page-sidebar.collapse-sidebar #search-menu {
      display: none;
    }

    .page-sidebar.collapse-sidebar .user-info {
      display: none;
    }

    .page-sidebar.collapse-sidebar .sidebar-panel {
      display: none;
    }

    .page-sidebar.collapse-sidebar .nav li {
      border-radius: 3px;
    }

      .page-sidebar.collapse-sidebar .nav li a {
        text-align: center;
        padding: 10px 14px 9px !important;
      }

        .page-sidebar.collapse-sidebar .nav li a .sideNav-arrow {
          display: none;
        }

        .page-sidebar.collapse-sidebar .nav li a .label {
          display: none;
        }

        .page-sidebar.collapse-sidebar .nav li a i {
          margin-right: 0 !important;
          float: none !important;
        }

        .page-sidebar.collapse-sidebar .nav li a span.txt {
          display: none;
          position: absolute;
          left: 45px;
          top: 0;
          z-index: 9998;
        }

      .page-sidebar.collapse-sidebar .nav li.hover-li {
        height: 42px;
      }

        .page-sidebar.collapse-sidebar .nav li.hover-li a {
          z-index: 999;
          width: 221px;
          background-color: #444548;
        }

          .page-sidebar.collapse-sidebar .nav li.hover-li a span.txt {
            display: inline-block;
            width: auto;
            padding: 10px 0 10px 20px;
          }

          .page-sidebar.collapse-sidebar .nav li.hover-li a i {
            color: #ffffff;
            float: left !important;
            margin-left: 6px;
          }

          .page-sidebar.collapse-sidebar .nav li.hover-li a.notExpand {
            border-bottom-right-radius: 0;
            border-top-right-radius: 3px;
            background-color: #444548;
            color: #ffffff;
          }

          .page-sidebar.collapse-sidebar .nav li.hover-li a.active:before {
            background-color: transparent !important;
            border-color: transparent !important;
          }

        .page-sidebar.collapse-sidebar .nav li.hover-li ul.sub {
          position: absolute;
          z-index: 9999;
          left: 60px;
          top: 40px;
          width: auto;
          display: block;
          margin: 0;
          background-color: #444548;
          list-style: none;
        }

          .page-sidebar.collapse-sidebar .nav li.hover-li ul.sub li {
            height: auto;
          }

            .page-sidebar.collapse-sidebar .nav li.hover-li ul.sub li a {
              width: 161px;
              padding: 0 5px 0 10px !important;
              border-radius: 0;
              background-color: #444548;
              box-shadow: none;
              font-size: 12px;
              text-align: left;
            }

              .page-sidebar.collapse-sidebar .nav li.hover-li ul.sub li a span.txt {
                position: relative;
                left: auto;
                top: auto;
                padding: 5px;
              }

              .page-sidebar.collapse-sidebar .nav li.hover-li ul.sub li a:before {
                border: none;
                background: none;
                float: none;
              }

              .page-sidebar.collapse-sidebar .nav li.hover-li ul.sub li a:after {
                background: none;
              }

            .page-sidebar.collapse-sidebar .nav li.hover-li ul.sub li.hasSub a.notExpand {
              background-color: #444548;
            }

            .page-sidebar.collapse-sidebar .nav li.hover-li ul.sub li.hasSub ul.sub {
              position: relative;
              height: 100%;
              top: -6px !important;
              left: 0;
              top: 37px;
              background-color: #444548;
            }

              .page-sidebar.collapse-sidebar .nav li.hover-li ul.sub li.hasSub ul.sub li a {
                padding: 0 5px 0 21px !important;
                border-radius: 0 !important;
                background-color: #444548;
                width: 161px;
              }

                .page-sidebar.collapse-sidebar .nav li.hover-li ul.sub li.hasSub ul.sub li a:after,
                .page-sidebar.collapse-sidebar .nav li.hover-li ul.sub li.hasSub ul.sub li a:before {
                  border: none;
                  background: none;
                }

              .page-sidebar.collapse-sidebar .nav li.hover-li ul.sub li.hasSub ul.sub li:last-child a {
                border-bottom-right-radius: 3px !important;
              }

              .page-sidebar.collapse-sidebar .nav li.hover-li ul.sub li.hasSub ul.sub li:first-child a {
                border-top-right-radius: 3px !important;
              }

            .page-sidebar.collapse-sidebar .nav li.hover-li ul.sub li + li {
              margin-top: 0;
            }

              .page-sidebar.collapse-sidebar .nav li.hover-li ul.sub li + li:last-child:not(.hasSub) {
                padding-bottom: 0;
              }

                .page-sidebar.collapse-sidebar .nav li.hover-li ul.sub li + li:last-child:not(.hasSub) a {
                  padding-bottom: 8px !important;
                }

            .page-sidebar.collapse-sidebar .nav li.hover-li ul.sub li:last-child a {
              border-bottom-right-radius: 3px;
            }

            .page-sidebar.collapse-sidebar .nav li.hover-li ul.sub li:first-child {
              margin-top: 8px;
            }

  .page-sidebar .side-nav {
    width: 100%;
    float: left;
  }

    .page-sidebar .side-nav .nav > li a, .page-sidebar .side-nav .nav > li button {
      padding: 10px 20px 9px;
      color: #ffffff;
      display: inline-block;
      width: 100%;
      position: relative;
      line-height: 20px;
      letter-spacing: 0.3px;
      transition: background;
      transition-duration: 0.4s;
    }

      .page-sidebar .side-nav .nav > li a i, .page-sidebar .side-nav .nav > li button i {
        font-size: 20px;
        margin-right: 15px;
        color: #ffffff;
        float: left;
        transition: color;
        transition-duration: 0.4s;
      }

        .page-sidebar .side-nav .nav > li a i.sideNav-arrow {
          float: right;
          margin-right: 5px;
          font-size: 14px;
          transition: all;
          transition-duration: 0.4s;
        }

      .page-sidebar .side-nav .nav > li a .txt {
        display: inline-block;
      }

      .page-sidebar .side-nav .nav > li a:hover, .page-sidebar .side-nav .nav > li button:hover {
        background-color: #444548;
        color: #ffffff;
      }

        .page-sidebar .side-nav .nav > li a:hover i, .page-sidebar .side-nav .nav > li button:hover i {
          color: #ffffff;
        }

      .page-sidebar .side-nav .nav > li a.active, .page-sidebar .side-nav .nav > li button.active {
        background-color: #444548;
        color: #ffffff;
      }

        .page-sidebar .side-nav .nav > li a.active i, .page-sidebar .side-nav .nav > li button.active i {
          color: #ffffff;
        }

        .page-sidebar .side-nav .nav > li a.active:before, .page-sidebar .side-nav .nav > li button.active:before{
          background-color: #ffffff !important;
          border-color: #ffffff !important;
        }

      .page-sidebar .side-nav .nav > li a.expand {
        background-color: #444548;
        color: #ffffff;
      }

        .page-sidebar .side-nav .nav > li a.expand i {
          color: #ffffff;
        }

      .page-sidebar .side-nav .nav > li a .label {
        float: right;
        border-radius: 50%;
        background-color: #505e73;
        padding: 6px 6px;
        margin-top: 0;
        line-height: 10px;
      }

    .page-sidebar .side-nav .nav > li ul.sub {
      padding-left: 0;
      display: none;
      position: relative;
    }

      .page-sidebar .side-nav .nav > li ul.sub > li {
        border-bottom: none;
        position: relative;
      }

        .page-sidebar .side-nav .nav > li ul.sub > li a {
          transition: color;
          transition-duration: 0.4s;
          padding: 5px 20px 4px 60px;
          position: relative;
        }

          .page-sidebar .side-nav .nav > li ul.sub > li a i {
            transition: color;
            transition-duration: 0.4s;
          }

          .page-sidebar .side-nav .nav > li ul.sub > li a:before {
            content: "";
            border: 1px solid #8b929a;
            border-radius: 50%;
            width: 7px;
            height: 7px;
            float: left;
            margin-top: 6px;
            left: 26px;
            position: absolute;
            z-index: 12;
            background-color: #444548;
            transition: border-color;
            transition-duration: 0.4s;
          }

          .page-sidebar .side-nav .nav > li ul.sub > li a:after {
            content: "";            
            height: 55px;
            width: 1px;
            background-color: #8b929a;
            position: absolute;
            top: 0;
            left: 29px;
            z-index: 10;
          }

          .page-sidebar .side-nav .nav > li ul.sub > li a:hover:before {
            border-color: #ffffff;
          }

        .page-sidebar .side-nav .nav > li ul.sub > li:first-child a {
          padding-top: 10px;
        }

          .page-sidebar .side-nav .nav > li ul.sub > li:first-child a.expand:after {
            height: 20px;
          }

        .page-sidebar .side-nav .nav > li ul.sub > li:last-child a {
          padding-bottom: 10px;
        }

          .page-sidebar .side-nav .nav > li ul.sub > li:last-child a:after {
            height: 18px;
          }

      .page-sidebar .side-nav .nav > li ul.sub.show li {
        position: relative;
      }

        .page-sidebar .side-nav .nav > li ul.sub.show li a {
          background-color: #444548;
          position: relative;
        }

          .page-sidebar .side-nav .nav > li ul.sub.show li a.expand:after {
            height: 18px;
          }

        .page-sidebar .side-nav .nav > li ul.sub.show li.highlight-menu:last-child:after {
          content: "";
          width: 12px;
          height: 1px;
          position: absolute;
          left: 33px;
          top: 14px;
          background-color: #8b929a;
        }

        .page-sidebar .side-nav .nav > li ul.sub.show li.highlight-menu:last-child a.expand {
          padding-bottom: 2px;
        }

        .page-sidebar .side-nav .nav > li ul.sub.show li.highlight-menu a.expand:after {
          height: 19px;
        }

        .page-sidebar .side-nav .nav > li ul.sub.show li .sub:before {
          content: "";
          width: 12px;
          height: 1px;
          position: absolute;
          left: 33px;
          top: -14px;
          background-color: #8b929a;
        }

        .page-sidebar .side-nav .nav > li ul.sub.show li .sub:after {
          content: "";
          width: 17px;
          height: 1px;
          position: absolute;
          left: 29px;
          bottom: 0px;
          background-color: #8b929a;
        }

        .page-sidebar .side-nav .nav > li ul.sub.show li .sub li a {
          padding: 3px 20px 2px 75px;
        }

          .page-sidebar .side-nav .nav > li ul.sub.show li .sub li a:before {
            left: 42px;
          }

          .page-sidebar .side-nav .nav > li ul.sub.show li .sub li a:after {
            left: 45px;
            height: 29px;
            top: -13px;
          }

        .page-sidebar .side-nav .nav > li ul.sub.show li .sub li:first-child {
          border-top: none;
        }

          .page-sidebar .side-nav .nav > li ul.sub.show li .sub li:first-child a {
            padding-top: 3px;
            padding-bottom: 2px;
          }

        .page-sidebar .side-nav .nav > li ul.sub.show li .sub li:last-child a {
          padding-top: 3px;
          padding-bottom: 10px;
        }

          .page-sidebar .side-nav .nav > li ul.sub.show li .sub li:last-child a:after {
            height: 46px;
          }

        .page-sidebar .side-nav .nav > li ul.sub.show li:last-child .sub:after {
          background-color: transparent;
        }

        .page-sidebar .side-nav .nav > li ul.sub.show li:last-child .sub li:last-child a:after {
          height: 29px;
        }

  .page-sidebar .user-info {
    float: left;
    padding: 10px 15px;
  }

    .page-sidebar .user-info img {
      border-radius: 3px;
      float: left;
      margin-right: 15px;
      width: 64px;
    }

    .page-sidebar .user-info .name {
      color: #ffffff;
      float: left;
      width: 100px;
      font-weight: normal;
      padding: 5px 0;
    }

    .page-sidebar .user-info button {
      font-size: 13px;
      margin-top: 2px;
    }

    .page-sidebar .user-info .btn-group .btn + .btn {
      border-left: none;
      padding-left: 3px;
    }

    .page-sidebar .user-info .dropdown-menu {
      min-width: 140px;
      border-color: #55565A;
    }

      .page-sidebar .user-info .dropdown-menu li {
        border-radius: 0;
      }

        .page-sidebar .user-info .dropdown-menu li a {
          padding: 8px 15px;
          width: inherit;
          line-height: inherit;
          letter-spacing: inherit;
          display: inherit;
        }

          .page-sidebar .user-info .dropdown-menu li a:hover {
            background-color: #f7f9fe;
            color: #333333;
          }

            .page-sidebar .user-info .dropdown-menu li a:hover i {
              color: #333333;
            }
/*-----------------------------------------
   Right Sidebar styles
----------------------------------------- */
#right-sidebar {
  width: 210px;
  height: auto;
  z-index: 10;
  float: right;
  padding-top: 50px;
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 2;
  display: block;
}

  #right-sidebar:after {
    content: "";
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 210px;
    background: #ffffff;
    border-left: 1px solid #e3e3e3;
    z-index: -1;
  }

  #right-sidebar.hide-sidebar {
    margin-right: -210px;
    display: none;
  }

    #right-sidebar.hide-sidebar:after {
      right: -210px;
    }

  #right-sidebar.sidebar-fixed {
    position: fixed;
  }

  #right-sidebar .sidebar-inner {
    position: relative;
    width: auto;
    height: 100%;
  }

  #right-sidebar .tabs {
    padding: 0 0 0 1px;
  }

    #right-sidebar .tabs .nav-tabs li a {
      border-radius: 0;
      background-color: #ffffff;
      padding: 15px 15px 14px;
      box-shadow: none;
      border-bottom: 1px solid #e3e3e3;
    }

      #right-sidebar .tabs .nav-tabs li a i {
        color: #777777;
      }

    #right-sidebar .tabs .nav-tabs li.active a {
      border-bottom: 1px solid #29b6d8;
    }

      #right-sidebar .tabs .nav-tabs li.active a:after {
        right: auto;
        left: auto;
        bottom: auto;
      }

    #right-sidebar .tabs .tab-content {
      box-shadow: none;
      border-radius: 0;
    }

  #right-sidebar .user-list {
    margin-left: -15px;
    margin-right: -15px;
  }

    #right-sidebar .user-list .list-group-item {
      border: none;
      line-height: 16px;
      position: relative;
      padding: 5px 15px;
    }

      #right-sidebar .user-list .list-group-item a {
        display: block;
        line-height: 16px;
        position: relative;
        padding: 5px 0;
        text-decoration: none;
        color: #555555;
      }

        #right-sidebar .user-list .list-group-item a .avatar {
          float: left;
          margin-right: 10px;
          border-radius: 50%;
          border: 2px solid #eeeeee;
          width: 42px;
          height: 42px;
        }

        #right-sidebar .user-list .list-group-item a .name {
          display: block;
          font-weight: bold;
          width: 100%;
          margin-top: 5px;
        }

        #right-sidebar .user-list .list-group-item a .status {
          width: 100%;
          display: block;
          font-size: 13px;
        }

          #right-sidebar .user-list .list-group-item a .status:before {
            content: "";
            border-radius: 50%;
            width: 8px;
            height: 8px;
            position: relative;
            float: left;
            margin-top: 4px;
            margin-right: 5px;
          }

          #right-sidebar .user-list .list-group-item a .status.status-online:before {
            background-color: #51bf87;
          }

          #right-sidebar .user-list .list-group-item a .status.status-offline:before {
            background-color: #db5565;
          }

  #right-sidebar .chat-messages {
    display: none;
    margin-right: -210px;
  }

    #right-sidebar .chat-messages.open {
      display: block;
      margin-right: 0;
      position: fixed;
      padding-left: 0;
      list-style: none;
      top: 51px;
      right: 0;
      bottom: 130px;
      background: #ffffff;
      width: 210px;
      z-index: 10;
      border-left: 1px solid #e3e3e3;
    }

    #right-sidebar .chat-messages ul {
      list-style: none;
      padding-left: 0;
      position: relative;
      height: 100%;
    }

    #right-sidebar .chat-messages li {
      position: relative;
      padding: 5px 10px;
      margin-bottom: 10px;
    }

      #right-sidebar .chat-messages li .avatar {
        float: left;
      }

        #right-sidebar .chat-messages li .avatar img {
          width: 42px;
          height: 42px;
          border-radius: 50%;
          margin-right: 5px;
          border: 1px solid transparent;
          transition: border;
          transition-duration: 0.4s;
        }

      #right-sidebar .chat-messages li .chat-name {
        margin-top: 8px;
        font-weight: bold;
      }

        #right-sidebar .chat-messages li .chat-name .chat-time {
          color: #777777;
          font-size: 10px;
          display: block;
        }

      #right-sidebar .chat-messages li .message {
        border-radius: 3px;
        background-color: #f9f9f9;
        padding: 10px;
        position: relative;
      }

        #right-sidebar .chat-messages li .message p {
          margin-bottom: 0;
        }

        #right-sidebar .chat-messages li .message:after,
        #right-sidebar .chat-messages li .message:before {
          bottom: 100%;
          left: 22px;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
        }

        #right-sidebar .chat-messages li .message:after {
          border-bottom-color: #f9f9f9;
          border-width: 6px;
          margin-left: -6px;
        }

        #right-sidebar .chat-messages li .message:before {
          border-bottom-color: #f9f9f9;
          border-width: 7px;
          margin-left: -7px;
        }

        #right-sidebar .chat-messages li .message.message-success {
          color: #ffffff;
          background-color: #9edbbc;
        }

          #right-sidebar .chat-messages li .message.message-success:after {
            border-bottom-color: #9edbbc;
          }

          #right-sidebar .chat-messages li .message.message-success:before {
            border-bottom-color: #9edbbc;
          }

        #right-sidebar .chat-messages li .message.message-info {
          color: #ffffff;
          background-color: #6acce4;
        }

          #right-sidebar .chat-messages li .message.message-info:after {
            border-bottom-color: #6acce4;
          }

          #right-sidebar .chat-messages li .message.message-info:before {
            border-bottom-color: #6acce4;
          }

        #right-sidebar .chat-messages li .message.message-danger {
          color: #ffffff;
          background-color: #eda9b1;
        }

          #right-sidebar .chat-messages li .message.message-danger:after {
            border-bottom-color: #eda9b1;
          }

          #right-sidebar .chat-messages li .message.message-danger:before {
            border-bottom-color: #eda9b1;
          }

      #right-sidebar .chat-messages li.chat-back {
        background-color: #eeeeee;
        border-bottom: 1px solid #e3e3e3;
        transition: background;
        transition-duration: 0.4s;
      }

        #right-sidebar .chat-messages li.chat-back:hover {
          background-color: #e4e4e4;
        }

      #right-sidebar .chat-messages li.chat-me .avatar {
        float: right;
        margin-left: 5px;
      }

      #right-sidebar .chat-messages li.chat-me .chat-name {
        text-align: right;
      }

      #right-sidebar .chat-messages li.chat-me .message:after,
      #right-sidebar .chat-messages li.chat-me .message:before {
        right: 18px;
        left: auto;
      }

  #right-sidebar #chat-write {
    display: none;
    margin-right: -210px;
    height: auto;
    float: left;
    position: fixed;
    bottom: 0;
    right: 0;
    background: #ffffff;
    width: 210px;
    border-left: 1px solid #e3e3e3;
    z-index: 11;
  }

    #right-sidebar #chat-write form {
      padding: 15px;
    }

    #right-sidebar #chat-write.open {
      display: block;
      margin-right: 0;
    }
/* -----------------------------------------
   Content
----------------------------------------- */

:root{
  --ms-sidebar-page: 250px;
  --ms-sidebar-sidebar: 60px;
}

.page-content {
  min-height: 100%;
  padding-top: 50px;
}

  .page-content.sidebar-page {
    margin-left: var(--ms-sidebar-page);
  }

  .page-content.collapsed-sidebar {
    margin-left: var(--ms-sidebar-sidebar);
  }

  .page-content.full-page {
    margin-left: 0;
    margin-right: 0;
  }

  .page-content.overLap {
    margin-left: 0;
  }

  .page-content.rOverLap {
    margin-right: 0;
  }

  .page-content.offCanvas {
    width: 100%;
    margin-left: 250px;
  }

  .page-content .page-content-wrapper {
    float: left;
    width: 100%;
    height: auto;
    position: relative;
    overflow: hidden;
  }

  .page-content .page-content-inner {
    position: relative;
    padding: 0 20px;
  }

  .page-content #page-header {
    background-color: #fcfcfc;
    margin-top: 1px;
    padding: 0 20px;
    margin-right: -20px;
    margin-left: -20px;
    margin-bottom: 20px;
    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.1);
  }

    .page-content #page-header .page-header {
      margin-top: 0px;
      margin-bottom: 15px;
      border-bottom: none;
      float: left;
      margin-right: 20px;
    }

      .page-content #page-header .page-header h2 {
        margin-bottom: 5px;
      }

    .page-content #page-header .header-stats {
      float: right;
      padding: 20px;
    }
/* -----------------------------------------
   Page footer
----------------------------------------- */
#footer {
  padding: 15px 20px;
  border-top: 1px solid #e3e3e3;
  height: 51px;
  bottom: 0;
  left: 0;
  right: 0;
}

  #footer p {
    margin-bottom: 0;
  }

  #footer.sidebar-page {
    margin-left: 250px;
  }

  #footer.collapsed-sidebar {
    margin-left: 60px;
  }

  #footer.full-page {
    margin-left: 0;
    margin-right: 0;
  }

  #footer.offCanvas {
    width: 100%;
    margin-left: 250px;
  }
/* -----------------------------------------
   Sparklines
----------------------------------------- */
.spark {
  display: inline-block;
  margin-left: 20px;
}

  .spark .spark-info {
    font-size: 14px;
    text-align: left;
    margin-bottom: 5px;
  }

    .spark .spark-info .number {
      font-weight: bold;
      font-size: 18px;
      padding-right: 5px;
      color: #0a97b9;
    }

  .spark .sparkline {
    width: 100%;
    display: inline-block;
    margin-top: 4px;
  }

.jqstooltip {
  box-sizing: content-box;
  border-color: #344154 !important;
  opacity: 0.8;
  filter: alpha(opacity=80);
  font-size: 12px;
  font-weight: bold;
  border-radius: 3px !important;
  background: #344154;
  font-family: verdana;
  width: auto !important;
  height: auto !important;
}

.sparkline {
  width: 100%;
  display: inline-block;
  margin-bottom: 5px;
}
/* -----------------------------------------
   Code styles
----------------------------------------- */
.pln {
  color: #000000;
}

@media screen {
  .str {
    color: #008800;
  }

  .kwd {
    color: #000088;
  }

  .com {
    color: #880000;
  }

  .typ {
    color: #660066;
  }

  .lit {
    color: #006666;
  }

  .pun,
  .opn,
  .clo {
    color: #666600;
  }

  .tag {
    color: #000088;
  }

  .atn {
    color: #660066;
  }

  .atv {
    color: #008800;
  }

  .dec,
  .var {
    color: #660066;
  }

  .fun {
    color: #ff0000;
  }
}

@media print, projection {
  .str {
    color: #006600;
  }

  .kwd {
    color: #006;
    font-weight: bold;
  }

  .com {
    color: #600;
    font-style: italic;
  }

  .typ {
    color: #404;
    font-weight: bold;
  }

  .lit {
    color: #004444;
  }

  .pun,
  .opn,
  .clo {
    color: #444400;
  }

  .tag {
    color: #006;
    font-weight: bold;
  }

  .atn {
    color: #440044;
  }

  .atv {
    color: #006600;
  }
}

pre.prettyprint {
  padding: 2px;
  border: 1px solid #888888;
}

ol.linenums {
  margin-top: 0;
  margin-bottom: 0;
}

li.L0,
li.L1,
li.L2,
li.L3,
li.L5,
li.L6,
li.L7,
li.L8 {
  list-style-type: none;
}

li.L1,
li.L3,
li.L5,
li.L7,
li.L9 {
  background: #eeeeee;
}
/* -----------------------------------------
   Back to top
----------------------------------------- */
#back-to-top {
  position: fixed;
  z-index: 1000;
  bottom: 10px;
  right: 10px;
  display: none;
}

  #back-to-top a {
    display: block;
    width: 32px;
    height: 32px;
    background: rgba(0, 0, 0, 0.4) url(../img/backtop.png) no-repeat center center;
    text-indent: -9999px;
    border-radius: 3px;
    transition: all;
    transition-duration: 0.4;
  }

    #back-to-top a:hover {
      background-color: #55565A;
    }

  #back-to-top.rightsidebar {
    right: 215px;
  }
/* -----------------------------------------
   Circfull progress bars
----------------------------------------- */
.circliful {
  position: relative;
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
}

  .circliful .circle-text,
  .circliful .circle-info,
  .circliful .circle-text-half,
  .circliful .circle-info-half {
    width: 100%;
    position: absolute;
    text-align: center;
    display: inline-block;
    color: #333333;
    font-weight: bold;
  }

  .circliful .circle-text-half {
    top: 8px;
  }

  .circliful i {
    margin: 5px 3px 0 3px;
    position: relative;
  }
/* -----------------------------------------
    Sidebar panels
----------------------------------------- */
.sidebar-panel {
  margin: 0;
  width: 100%;
  height: auto;
  float: left;
  position: relative;
}

  .sidebar-panel:before,
  .sidebar-panel:after {
    content: " ";
    display: table;
  }

  .sidebar-panel:after {
    clear: both;
  }

  .sidebar-panel .sidebar-panel-title {
    font-size: 13px;
    padding: 8px 15px 8px 17px;
    margin: 0;
    background-color: #81639A;
    color: #ffffff;
    border-top: 1px solid #81639A;
    border-bottom: 1px solid #81639A;
  }

    .sidebar-panel .sidebar-panel-title i {
      margin-right: 15px;
    }

  .sidebar-panel .sidebar-panel-content {
    padding: 15px;
    position: relative;
    color: #8b929a;
  }

    .sidebar-panel .sidebar-panel-content.fullwidth {
      padding: 5px 0px 15px;
    }

.sidebar-panel-content #input-filter {
  margin: 11px 0px 11px 14px;
  border-radius: 5px;
  padding-left: 7px;
  width: 170px;
  border-width: 1px;
}
/* -----------------------------------------
   Carousel dot style indicators
----------------------------------------- */
.dotstyle {
  display: inline-block;
  margin: 0;
  padding: 0;
  list-style: none;
  cursor: default;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  bottom: 10px !important;
}

  .dotstyle.center {
    width: auto;
    left: 45%;
  }

  .dotstyle li {
    position: relative;
    display: block;
    float: left;
    margin: 0 5px;
    width: 16px;
    height: 16px;
    cursor: pointer;
    border-radius: 50%;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0);
    transition: box-shadow 0.3s ease;
    border: none;
    background-color: none;
  }

    .dotstyle li a {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      outline: none;
      border-radius: 50%;
      background-color: #fff;
      background-color: rgba(255, 255, 255, 0.3);
      text-indent: -999em;
      /* make the text accessible to screen readers */
      cursor: pointer;
      position: absolute;
      background-color: rgba(255, 255, 255, 0.7);
      transition: background-color 0.3s ease, -webkit-transform 0.3s ease;
      transition: background-color 0.3s ease, transform 0.3s ease;
    }

      .dotstyle li a:hover,
      .dotstyle li a:focus {
        background-color: white;
      }

      .dotstyle li a:focus {
        outline: none;
      }

    .dotstyle li.active {
      margin: 0 5px;
      width: 16px;
      height: 16px;
      background: none;
      box-shadow: 0 0 0 2px white;
    }

      .dotstyle li.active a {
        -webkit-transform: scale(0.4);
        -ms-transform: scale(0.4);
        transform: scale(0.4);
        background-color: white;
      }
/* -----------------------------------------
   Datatables row fix
----------------------------------------- */
.dataTables_wrapper .row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

  .dataTables_wrapper .row .col-xs-6:first-child {
    padding-left: 0;
  }

  .dataTables_wrapper .row .col-xs-6:last-child {
    padding-right: 0;
  }

.dataTables_paginate .pagination {
  margin-top: -5px;
  float: right;
}
/* -----------------------------------------
   Pricing tables
----------------------------------------- */
.pricing-table {
  position: relative;
  background: #ffffff;
  margin-top: 25px;
  margin-bottom: 50px;
}

  .pricing-table .pricing-header {
    border: 1px solid #e3e3e3;
    border-bottom: none;
    width: 100%;
    text-align: center;
  }

    .pricing-table .pricing-header h3 {
      width: 70%;
      margin-left: 15%;
      margin-right: 15%;
      display: inline-block;
      text-transform: uppercase;
      background-color: #d5d5d5;
      border: 5px solid #ffffff;
      padding: 15px 0;
      margin: 0;
      margin-top: -25px;
      position: relative;
    }

      .pricing-table .pricing-header h3 i {
        font-size: 48px;
        position: absolute;
        top: -5px;
        left: 15px;
      }

  .pricing-table ul.list-group {
    margin-bottom: -1px;
    margin-top: -1px;
  }

    .pricing-table ul.list-group li:first-child {
      border-top: none;
    }

    .pricing-table ul.list-group li:last-child {
      border-bottom: none;
      padding-bottom: 20px;
    }

    .pricing-table ul.list-group li {
      border-radius: 0;
      font-size: 16px;
      text-transform: uppercase;
    }

      .pricing-table ul.list-group li i {
        margin: 0 15px;
      }

      .pricing-table ul.list-group li.off {
        text-decoration: line-through;
      }

  .pricing-table .pricing-bottom {
    border: 1px solid #e3e3e3;
    border-top: none;
    width: 100%;
    text-align: center;
  }

    .pricing-table .pricing-bottom .price {
      width: 70%;
      margin-left: 15%;
      margin-right: 15%;
      background-color: #f2f2f2;
      padding-top: 5px;
      margin-bottom: -25px;
    }

      .pricing-table .pricing-bottom .price span {
        font-weight: bold;
        font-size: 48px;
      }

      .pricing-table .pricing-bottom .price .btn {
        border-radius: 0;
      }

  .pricing-table.blue {
    background-color: #29b6d8;
  }

    .pricing-table.blue .pricing-header {
      border: 1px solid #24a5c4;
    }

      .pricing-table.blue .pricing-header h3 {
        background-color: #54c5e0;
        color: #ffffff;
      }

        .pricing-table.blue .pricing-header h3 i {
          color: #ffffff;
        }

    .pricing-table.blue ul.list-group li:first-child {
      border-top: none;
    }

    .pricing-table.blue ul.list-group li:last-child {
      border-bottom: none;
    }

    .pricing-table.blue ul.list-group li {
      background-color: #29b6d8;
      border: 1px solid #24a5c4;
      color: #ffffff;
    }

    .pricing-table.blue .pricing-bottom {
      border: 1px solid #24a5c4;
    }

      .pricing-table.blue .pricing-bottom .price {
        background-color: #2093ae;
        color: #ffffff;
      }

        .pricing-table.blue .pricing-bottom .price .btn {
          color: #ffffff;
          background-color: #145c6e;
          border-color: #145c6e;
        }

          .pricing-table.blue .pricing-bottom .price .btn:focus,
          .pricing-table.blue .pricing-bottom .price .btn.focus {
            color: #ffffff;
            background-color: #0c3842;
            border-color: #000102;
          }

          .pricing-table.blue .pricing-bottom .price .btn:hover {
            color: #ffffff;
            background-color: #0c3842;
            border-color: #0b313a;
          }

          .pricing-table.blue .pricing-bottom .price .btn:active,
          .pricing-table.blue .pricing-bottom .price .btn.active,
          .open > .dropdown-toggle.pricing-table.blue .pricing-bottom .price .btn {
            color: #ffffff;
            background-color: #0c3842;
            border-color: #0b313a;
          }

            .pricing-table.blue .pricing-bottom .price .btn:active:hover,
            .pricing-table.blue .pricing-bottom .price .btn.active:hover,
            .open > .dropdown-toggle.pricing-table.blue .pricing-bottom .price .btn:hover,
            .pricing-table.blue .pricing-bottom .price .btn:active:focus,
            .pricing-table.blue .pricing-bottom .price .btn.active:focus,
            .open > .dropdown-toggle.pricing-table.blue .pricing-bottom .price .btn:focus,
            .pricing-table.blue .pricing-bottom .price .btn:active.focus,
            .pricing-table.blue .pricing-bottom .price .btn.active.focus,
            .open > .dropdown-toggle.pricing-table.blue .pricing-bottom .price .btn.focus {
              color: #ffffff;
              background-color: #071e24;
              border-color: #000102;
            }

          .pricing-table.blue .pricing-bottom .price .btn:active,
          .pricing-table.blue .pricing-bottom .price .btn.active,
          .open > .dropdown-toggle.pricing-table.blue .pricing-bottom .price .btn {
            background-image: none;
          }

          .pricing-table.blue .pricing-bottom .price .btn.disabled,
          .pricing-table.blue .pricing-bottom .price .btn[disabled],
          fieldset[disabled] .pricing-table.blue .pricing-bottom .price .btn,
          .pricing-table.blue .pricing-bottom .price .btn.disabled:hover,
          .pricing-table.blue .pricing-bottom .price .btn[disabled]:hover,
          fieldset[disabled] .pricing-table.blue .pricing-bottom .price .btn:hover,
          .pricing-table.blue .pricing-bottom .price .btn.disabled:focus,
          .pricing-table.blue .pricing-bottom .price .btn[disabled]:focus,
          fieldset[disabled] .pricing-table.blue .pricing-bottom .price .btn:focus,
          .pricing-table.blue .pricing-bottom .price .btn.disabled.focus,
          .pricing-table.blue .pricing-bottom .price .btn[disabled].focus,
          fieldset[disabled] .pricing-table.blue .pricing-bottom .price .btn.focus,
          .pricing-table.blue .pricing-bottom .price .btn.disabled:active,
          .pricing-table.blue .pricing-bottom .price .btn[disabled]:active,
          fieldset[disabled] .pricing-table.blue .pricing-bottom .price .btn:active,
          .pricing-table.blue .pricing-bottom .price .btn.disabled.active,
          .pricing-table.blue .pricing-bottom .price .btn[disabled].active,
          fieldset[disabled] .pricing-table.blue .pricing-bottom .price .btn.active {
            background-color: #145c6e;
            border-color: #145c6e;
          }

          .pricing-table.blue .pricing-bottom .price .btn .badge {
            color: #145c6e;
            background-color: #ffffff;
          }

  .pricing-table.red {
    background-color: #df6a78;
  }

    .pricing-table.red .pricing-header {
      border: 1px solid #da5565;
    }

      .pricing-table.red .pricing-header h3 {
        background-color: #e8949e;
        color: #ffffff;
      }

    .pricing-table.red ul.list-group li:first-child {
      border-top: none;
    }

    .pricing-table.red ul.list-group li:last-child {
      border-bottom: none;
    }

    .pricing-table.red ul.list-group li {
      background-color: #df6a78;
      border: 1px solid #da5565;
      color: #ffffff;
    }

    .pricing-table.red .pricing-bottom {
      border: 1px solid #da5565;
    }

      .pricing-table.red .pricing-bottom .price {
        background-color: #d64052;
        color: #ffffff;
      }

        .pricing-table.red .pricing-bottom .price .btn {
          color: #ffffff;
          background-color: #a62433;
          border-color: #a62433;
        }

          .pricing-table.red .pricing-bottom .price .btn:focus,
          .pricing-table.red .pricing-bottom .price .btn.focus {
            color: #ffffff;
            background-color: #7c1b26;
            border-color: #3d0d13;
          }

          .pricing-table.red .pricing-bottom .price .btn:hover {
            color: #ffffff;
            background-color: #7c1b26;
            border-color: #731924;
          }

          .pricing-table.red .pricing-bottom .price .btn:active,
          .pricing-table.red .pricing-bottom .price .btn.active,
          .open > .dropdown-toggle.pricing-table.red .pricing-bottom .price .btn {
            color: #ffffff;
            background-color: #7c1b26;
            border-color: #731924;
          }

            .pricing-table.red .pricing-bottom .price .btn:active:hover,
            .pricing-table.red .pricing-bottom .price .btn.active:hover,
            .open > .dropdown-toggle.pricing-table.red .pricing-bottom .price .btn:hover,
            .pricing-table.red .pricing-bottom .price .btn:active:focus,
            .pricing-table.red .pricing-bottom .price .btn.active:focus,
            .open > .dropdown-toggle.pricing-table.red .pricing-bottom .price .btn:focus,
            .pricing-table.red .pricing-bottom .price .btn:active.focus,
            .pricing-table.red .pricing-bottom .price .btn.active.focus,
            .open > .dropdown-toggle.pricing-table.red .pricing-bottom .price .btn.focus {
              color: #ffffff;
              background-color: #5f141d;
              border-color: #3d0d13;
            }

          .pricing-table.red .pricing-bottom .price .btn:active,
          .pricing-table.red .pricing-bottom .price .btn.active,
          .open > .dropdown-toggle.pricing-table.red .pricing-bottom .price .btn {
            background-image: none;
          }

          .pricing-table.red .pricing-bottom .price .btn.disabled,
          .pricing-table.red .pricing-bottom .price .btn[disabled],
          fieldset[disabled] .pricing-table.red .pricing-bottom .price .btn,
          .pricing-table.red .pricing-bottom .price .btn.disabled:hover,
          .pricing-table.red .pricing-bottom .price .btn[disabled]:hover,
          fieldset[disabled] .pricing-table.red .pricing-bottom .price .btn:hover,
          .pricing-table.red .pricing-bottom .price .btn.disabled:focus,
          .pricing-table.red .pricing-bottom .price .btn[disabled]:focus,
          fieldset[disabled] .pricing-table.red .pricing-bottom .price .btn:focus,
          .pricing-table.red .pricing-bottom .price .btn.disabled.focus,
          .pricing-table.red .pricing-bottom .price .btn[disabled].focus,
          fieldset[disabled] .pricing-table.red .pricing-bottom .price .btn.focus,
          .pricing-table.red .pricing-bottom .price .btn.disabled:active,
          .pricing-table.red .pricing-bottom .price .btn[disabled]:active,
          fieldset[disabled] .pricing-table.red .pricing-bottom .price .btn:active,
          .pricing-table.red .pricing-bottom .price .btn.disabled.active,
          .pricing-table.red .pricing-bottom .price .btn[disabled].active,
          fieldset[disabled] .pricing-table.red .pricing-bottom .price .btn.active {
            background-color: #a62433;
            border-color: #a62433;
          }

          .pricing-table.red .pricing-bottom .price .btn .badge {
            color: #a62433;
            background-color: #ffffff;
          }

  .pricing-table.green {
    background-color: #66c796;
  }

    .pricing-table.green .pricing-header {
      border: 1px solid #53c089;
    }

      .pricing-table.green .pricing-header h3 {
        background-color: #8bd5b0;
        color: #ffffff;
      }

    .pricing-table.green ul.list-group li:first-child {
      border-top: none;
    }

    .pricing-table.green ul.list-group li:last-child {
      border-bottom: none;
    }

    .pricing-table.green ul.list-group li {
      background-color: #66c796;
      border: 1px solid #53c089;
      color: #ffffff;
    }

    .pricing-table.green .pricing-bottom {
      border: 1px solid #53c089;
    }

      .pricing-table.green .pricing-bottom .price {
        background-color: #43b77c;
        color: #ffffff;
      }

        .pricing-table.green .pricing-bottom .price .btn {
          color: #ffffff;
          background-color: #2e7f56;
          border-color: #2e7f56;
        }

          .pricing-table.green .pricing-bottom .price .btn:focus,
          .pricing-table.green .pricing-bottom .price .btn.focus {
            color: #ffffff;
            background-color: #215a3d;
            border-color: #0c2217;
          }

          .pricing-table.green .pricing-bottom .price .btn:hover {
            color: #ffffff;
            background-color: #215a3d;
            border-color: #1e5238;
          }

          .pricing-table.green .pricing-bottom .price .btn:active,
          .pricing-table.green .pricing-bottom .price .btn.active,
          .open > .dropdown-toggle.pricing-table.green .pricing-bottom .price .btn {
            color: #ffffff;
            background-color: #215a3d;
            border-color: #1e5238;
          }

            .pricing-table.green .pricing-bottom .price .btn:active:hover,
            .pricing-table.green .pricing-bottom .price .btn.active:hover,
            .open > .dropdown-toggle.pricing-table.green .pricing-bottom .price .btn:hover,
            .pricing-table.green .pricing-bottom .price .btn:active:focus,
            .pricing-table.green .pricing-bottom .price .btn.active:focus,
            .open > .dropdown-toggle.pricing-table.green .pricing-bottom .price .btn:focus,
            .pricing-table.green .pricing-bottom .price .btn:active.focus,
            .pricing-table.green .pricing-bottom .price .btn.active.focus,
            .open > .dropdown-toggle.pricing-table.green .pricing-bottom .price .btn.focus {
              color: #ffffff;
              background-color: #17402b;
              border-color: #0c2217;
            }

          .pricing-table.green .pricing-bottom .price .btn:active,
          .pricing-table.green .pricing-bottom .price .btn.active,
          .open > .dropdown-toggle.pricing-table.green .pricing-bottom .price .btn {
            background-image: none;
          }

          .pricing-table.green .pricing-bottom .price .btn.disabled,
          .pricing-table.green .pricing-bottom .price .btn[disabled],
          fieldset[disabled] .pricing-table.green .pricing-bottom .price .btn,
          .pricing-table.green .pricing-bottom .price .btn.disabled:hover,
          .pricing-table.green .pricing-bottom .price .btn[disabled]:hover,
          fieldset[disabled] .pricing-table.green .pricing-bottom .price .btn:hover,
          .pricing-table.green .pricing-bottom .price .btn.disabled:focus,
          .pricing-table.green .pricing-bottom .price .btn[disabled]:focus,
          fieldset[disabled] .pricing-table.green .pricing-bottom .price .btn:focus,
          .pricing-table.green .pricing-bottom .price .btn.disabled.focus,
          .pricing-table.green .pricing-bottom .price .btn[disabled].focus,
          fieldset[disabled] .pricing-table.green .pricing-bottom .price .btn.focus,
          .pricing-table.green .pricing-bottom .price .btn.disabled:active,
          .pricing-table.green .pricing-bottom .price .btn[disabled]:active,
          fieldset[disabled] .pricing-table.green .pricing-bottom .price .btn:active,
          .pricing-table.green .pricing-bottom .price .btn.disabled.active,
          .pricing-table.green .pricing-bottom .price .btn[disabled].active,
          fieldset[disabled] .pricing-table.green .pricing-bottom .price .btn.active {
            background-color: #2e7f56;
            border-color: #2e7f56;
          }

          .pricing-table.green .pricing-bottom .price .btn .badge {
            color: #2e7f56;
            background-color: #ffffff;
          }

  .pricing-table.yellow {
    background-color: #f5b75f;
  }

    .pricing-table.yellow .pricing-header {
      border: 1px solid #f4ac47;
    }

      .pricing-table.yellow .pricing-header h3 {
        background-color: #f8cd8f;
        color: #ffffff;
      }

    .pricing-table.yellow ul.list-group li:first-child {
      border-top: none;
    }

    .pricing-table.yellow ul.list-group li:last-child {
      border-bottom: none;
    }

    .pricing-table.yellow ul.list-group li {
      background-color: #f5b75f;
      border: 1px solid #f4ac47;
      color: #ffffff;
    }

    .pricing-table.yellow .pricing-bottom {
      border: 1px solid #f4ac47;
    }

      .pricing-table.yellow .pricing-bottom .price {
        background-color: #f2a12f;
        color: #ffffff;
      }

        .pricing-table.yellow .pricing-bottom .price .btn {
          color: #ffffff;
          background-color: #c87b0c;
          border-color: #c87b0c;
        }

          .pricing-table.yellow .pricing-bottom .price .btn:focus,
          .pricing-table.yellow .pricing-bottom .price .btn.focus {
            color: #ffffff;
            background-color: #985d09;
            border-color: #503105;
          }

          .pricing-table.yellow .pricing-bottom .price .btn:hover {
            color: #ffffff;
            background-color: #985d09;
            border-color: #8e5709;
          }

          .pricing-table.yellow .pricing-bottom .price .btn:active,
          .pricing-table.yellow .pricing-bottom .price .btn.active,
          .open > .dropdown-toggle.pricing-table.yellow .pricing-bottom .price .btn {
            color: #ffffff;
            background-color: #985d09;
            border-color: #8e5709;
          }

            .pricing-table.yellow .pricing-bottom .price .btn:active:hover,
            .pricing-table.yellow .pricing-bottom .price .btn.active:hover,
            .open > .dropdown-toggle.pricing-table.yellow .pricing-bottom .price .btn:hover,
            .pricing-table.yellow .pricing-bottom .price .btn:active:focus,
            .pricing-table.yellow .pricing-bottom .price .btn.active:focus,
            .open > .dropdown-toggle.pricing-table.yellow .pricing-bottom .price .btn:focus,
            .pricing-table.yellow .pricing-bottom .price .btn:active.focus,
            .pricing-table.yellow .pricing-bottom .price .btn.active.focus,
            .open > .dropdown-toggle.pricing-table.yellow .pricing-bottom .price .btn.focus {
              color: #ffffff;
              background-color: #764907;
              border-color: #503105;
            }

          .pricing-table.yellow .pricing-bottom .price .btn:active,
          .pricing-table.yellow .pricing-bottom .price .btn.active,
          .open > .dropdown-toggle.pricing-table.yellow .pricing-bottom .price .btn {
            background-image: none;
          }

          .pricing-table.yellow .pricing-bottom .price .btn.disabled,
          .pricing-table.yellow .pricing-bottom .price .btn[disabled],
          fieldset[disabled] .pricing-table.yellow .pricing-bottom .price .btn,
          .pricing-table.yellow .pricing-bottom .price .btn.disabled:hover,
          .pricing-table.yellow .pricing-bottom .price .btn[disabled]:hover,
          fieldset[disabled] .pricing-table.yellow .pricing-bottom .price .btn:hover,
          .pricing-table.yellow .pricing-bottom .price .btn.disabled:focus,
          .pricing-table.yellow .pricing-bottom .price .btn[disabled]:focus,
          fieldset[disabled] .pricing-table.yellow .pricing-bottom .price .btn:focus,
          .pricing-table.yellow .pricing-bottom .price .btn.disabled.focus,
          .pricing-table.yellow .pricing-bottom .price .btn[disabled].focus,
          fieldset[disabled] .pricing-table.yellow .pricing-bottom .price .btn.focus,
          .pricing-table.yellow .pricing-bottom .price .btn.disabled:active,
          .pricing-table.yellow .pricing-bottom .price .btn[disabled]:active,
          fieldset[disabled] .pricing-table.yellow .pricing-bottom .price .btn:active,
          .pricing-table.yellow .pricing-bottom .price .btn.disabled.active,
          .pricing-table.yellow .pricing-bottom .price .btn[disabled].active,
          fieldset[disabled] .pricing-table.yellow .pricing-bottom .price .btn.active {
            background-color: #c87b0c;
            border-color: #c87b0c;
          }

          .pricing-table.yellow .pricing-bottom .price .btn .badge {
            color: #c87b0c;
            background-color: #ffffff;
          }
/* -----------------------------------------
   Input sizes
----------------------------------------- */
.input-mini {
  width: 60px !important;
}

.input-small {
  width: 90px !important;
}

.input-medium {
  width: 150px !important;
}

.input-large {
  width: 210px !important;
}

.input-xlarge {
  width: 270px !important;
}

.input-block {
  width: 100%;
  display: block;
}
/* -----------------------------------------
   Touchspin - spinner
----------------------------------------- */
.bootstrap-touchspin .input-group-btn-vertical {
  position: relative;
  white-space: nowrap;
  width: 1%;
  vertical-align: middle;
  display: table-cell;
}

  .bootstrap-touchspin .input-group-btn-vertical > .btn {
    display: block;
    float: none;
    width: 100%;
    max-width: 100%;
    padding: 8px 10px;
    margin-left: -1px;
    position: relative;
    border: 1px solid #cacaca;
  }

  .bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-up {
    border-radius: 0;
    border-top-right-radius: 4px;
  }

  .bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down {
    margin-top: -2px;
    border-radius: 0;
    border-bottom-right-radius: 4px;
  }

  .bootstrap-touchspin .input-group-btn-vertical i {
    position: absolute;
    top: 3px;
    left: 5px;
    font-size: 9px;
    font-weight: normal;
    color: #808080;
  }

.bootstrap-touchspin .bootstrap-touchspin-prefix {
  border-right: 0;
}

.bootstrap-touchspin .bootstrap-touchspin-postfix {
  border-left: 0;
}
/* -----------------------------------------
   Bootstrap typeahead
----------------------------------------- */
.tt-input,
.tt-hint {
  width: 260px;
  height: 24px;
  padding: 4px 6px;
  font-size: 16px;
  line-height: 24px;
  border: 1px solid #efefef;
  border-radius: 3px;
  outline: none;
}

.tt-input {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.tt-hint {
  color: #999999;
}

.tt-dropdown-menu {
  width: 250px;
  margin-top: 5px;
  padding: 5px 0;
  background-color: #ffffff;
  border: 1px solid #efefef;
  border-radius: 3px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.tt-suggestion {
  padding: 3px 10px;
  font-size: 14px;
  line-height: 24px;
}

  .tt-suggestion.tt-cursor {
    color: #1a1a1a;
    background-color: #f7f9fe;
  }

  .tt-suggestion p {
    margin: 0;
  }
/* -----------------------------------------
   Flot charts
----------------------------------------- */
#flotTip {
  /* white-space: nowrap;
    display: inline-block; */
  max-width: 150px;
  padding: 3px 8px;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  background-color: #344154 !important;
  border-radius: 3px !important;
  border-width: 0 !important;
  z-index: 100;
  opacity: 0.8;
  filter: alpha(opacity=80);
  font-family: "Roboto";
  font-size: 12px;
}

.tickLabel {
  font-family: "Roboto";
  font-size: 11px;
  text-align: center;
  font-weight: normal;
}
/* -----------------------------------------
   Easy pie charts
----------------------------------------- */
.easyPieChart {
  position: relative;
  text-align: center;
}

  .easyPieChart canvas {
    position: absolute;
    top: 0;
    left: 0;
  }

.pie-charts {
  display: inline-block;
  text-align: center;
  padding: 0 12px;
  margin-bottom: 20px;
}

  .pie-charts .easyPieChart {
    font-weight: bold;
  }

  .pie-charts div.label {
    color: #333333;
    font-size: 100%;
    width: 100%;
    float: left;
    margin-top: 10px;
  }

  .pie-charts.red-pie .easyPieChart {
    color: #db5565;
  }

  .pie-charts.red-pie div.label {
    color: #db5565;
  }

  .pie-charts.green-pie .easyPieChart {
    color: #51bf87;
  }

  .pie-charts.green-pie div.label {
    color: #51bf87;
  }

  .pie-charts.blue-pie .easyPieChart {
    color: #0bacd3;
  }

  .pie-charts.blue-pie div.label {
    color: #0bacd3;
  }

  .pie-charts.yellow-pie .easyPieChart {
    color: #f4ad49;
  }

  .pie-charts.yellow-pie div.label {
    color: #f4ad49;
  }
/* -----------------------------------------
  Gauges
----------------------------------------- */
.glow-gauge,
.gauge {
  display: inline-block;
  text-align: center;
  padding: 0 12px;
  margin-bottom: 20px;
}
/* -----------------------------------------
   Vectormaps
----------------------------------------- */
.jvectormap-label {
  position: absolute;
  display: none;
  border: solid 1px #344154;
  border-radius: 3px;
  background: #344154;
  color: #ffffff;
  font-family: sans-serif, Verdana;
  font-size: smaller;
  padding: 3px 5px;
  z-index: 999;
}

.jvectormap-zoomin,
.jvectormap-zoomout {
  position: absolute;
  left: 10px;
  border-radius: 3px;
  background: #344154;
  padding: 3px;
  color: white;
  width: 18px;
  height: 18px;
  cursor: pointer;
  line-height: 10px;
  text-align: center;
}

.jvectormap-zoomin {
  top: 10px;
}

.jvectormap-zoomout {
  top: 30px;
}
/* -----------------------------------------
   Highlight rows (tables, list and etc )
----------------------------------------- */
.highlight {
  background: #fffed5;
}

  .highlight td {
    background: #fffed5;
  }

  .highlight:hover {
    background: #fffdbc !important;
  }
/* -----------------------------------------
   Seperator
----------------------------------------- */
.seperator {
  text-align: center;
  width: 100%;
  display: inline-block;
}

  .seperator strong {
    background: white;
    padding-left: 10px;
    padding-right: 10px;
  }

  .seperator hr {
    margin-top: -10px;
  }
/* -----------------------------------------
   Timeline
----------------------------------------- */
.timeline {
  list-style: none;
  padding-left: 0;
  position: relative;
}

  .timeline:after {
    content: "";
    height: auto;
    width: 1px;
    background: #e3e3e3;
    position: absolute;
    top: 5px;
    left: 30px;
    bottom: 25px;
  }

  .timeline.timeline-sm:after {
    left: 12px;
  }

  .timeline li {
    position: relative;
    padding-left: 70px;
    margin-bottom: 20px;
  }

    .timeline li:after {
      content: "";
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #e3e3e3;
      position: absolute;
      left: 24px;
      top: 5px;
    }

    .timeline li .timeline-date {
      display: inline-block;
      width: 100%;
      color: #a6a6a6;
      font-style: italic;
      font-size: 13px;
    }

  .timeline.timeline-icons li {
    padding-top: 7px;
  }

    .timeline.timeline-icons li:after {
      width: 32px;
      height: 32px;
      background: #fff;
      border: 1px solid #e3e3e3;
      left: 14px;
      top: 0;
      z-index: 11;
    }

    .timeline.timeline-icons li .timeline-icon {
      position: absolute;
      left: 23.5px;
      top: 7px;
      z-index: 12;
    }

      .timeline.timeline-icons li .timeline-icon [class*=glyphicon] {
        top: -1px !important;
      }

  .timeline.timeline-icons.timeline-sm li {
    padding-left: 40px;
    margin-bottom: 10px;
  }

    .timeline.timeline-icons.timeline-sm li:after {
      left: -5px;
    }

    .timeline.timeline-icons.timeline-sm li .timeline-icon {
      left: 4.5px;
    }

  .timeline.timeline-advanced li {
    padding-top: 0;
  }

    .timeline.timeline-advanced li:after {
      background: #fff;
      border: 1px solid #29b6d8;
    }

    .timeline.timeline-advanced li:before {
      content: "";
      width: 52px;
      height: 52px;
      border: 10px solid #fff;
      position: absolute;
      left: 4px;
      top: -10px;
      border-radius: 50%;
      z-index: 12;
    }

    .timeline.timeline-advanced li .timeline-icon {
      color: #29b6d8;
    }

    .timeline.timeline-advanced li .timeline-date {
      width: 75px;
      position: absolute;
      right: 5px;
      top: 3px;
      text-align: right;
    }

    .timeline.timeline-advanced li .timeline-title {
      font-size: 17px;
      margin-bottom: 0;
      padding-top: 5px;
      font-weight: bold;
    }

    .timeline.timeline-advanced li .timeline-subtitle {
      display: inline-block;
      width: 100%;
      color: #a6a6a6;
    }

    .timeline.timeline-advanced li .timeline-content {
      margin-top: 10px;
      margin-bottom: 10px;
      padding-right: 70px;
    }

      .timeline.timeline-advanced li .timeline-content p {
        margin-bottom: 3px;
      }

      .timeline.timeline-advanced li .timeline-content .divider-dashed {
        padding-top: 0px;
        margin-bottom: 7px;
        width: 200px;
      }

    .timeline.timeline-advanced li .timeline-user {
      display: inline-block;
      width: 100%;
      margin-bottom: 10px;
    }

      .timeline.timeline-advanced li .timeline-user:before,
      .timeline.timeline-advanced li .timeline-user:after {
        content: " ";
        display: table;
      }

      .timeline.timeline-advanced li .timeline-user:after {
        clear: both;
      }

      .timeline.timeline-advanced li .timeline-user .timeline-avatar {
        border-radius: 50%;
        width: 32px;
        height: 32px;
        float: left;
        margin-right: 10px;
      }

      .timeline.timeline-advanced li .timeline-user .timeline-user-name {
        font-weight: bold;
        margin-bottom: 0;
      }

      .timeline.timeline-advanced li .timeline-user .timeline-user-subtitle {
        color: #a6a6a6;
        margin-top: -4px;
        margin-bottom: 0;
      }

    .timeline.timeline-advanced li .timeline-link {
      margin-left: 5px;
      display: inline-block;
    }

.timeline-load-more-btn {
  margin-left: 70px;
}

  .timeline-load-more-btn i {
    margin-right: 5px;
  }
/* -----------------------------------------
   Dividers
----------------------------------------- */
.divider-dashed {
  border-bottom: 1px dashed #e3e3e3;
  padding: 5px 0;
}
/* -----------------------------------------
   Progressbars stats
----------------------------------------- */
.progressbars-stats li {
  margin-bottom: 10px;
}

  .progressbars-stats li:last-child {
    margin-bottom: 0;
  }

  .progressbars-stats li .progressbar-icon {
    float: left;
    width: 50px;
    height: 40px;
    text-align: center;
  }

    .progressbars-stats li .progressbar-icon i {
      font-size: 24px;
      color: #777777;
      margin-top: 8px;
    }

      .progressbars-stats li .progressbar-icon i[class^="fa"] {
        margin-top: 13px;
      }

.progressbar-stats-1 {
  color: #ffffff;
}

  .progressbar-stats-1.dark {
    color: #666666;
  }

  .progressbar-stats-1 .stats i {
    font-size: 42px;
    float: left;
    margin-right: 15px;
  }

  .progressbar-stats-1 .stats-number {
    font-size: 42px;
    margin-top: -10px;
  }

.money:before {
  content: 'R$';
  margin-right: 5px;
}
/* -----------------------------------------
   Profile
----------------------------------------- */
.profile h5 {
  font-size: 16px;
}

.profile .profile-avatar {
  text-align: center;
  position: relative;
}

  .profile .profile-avatar img {
    border-radius: 50%;
  }

  .profile .profile-avatar .status {
    background-color: #df6a78;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: inline-block;
    position: absolute;
    bottom: 30px;
    right: 24px;
    border: 5px solid white;
  }

    .profile .profile-avatar .status.online {
      background-color: #66c796;
    }

.profile .profile-name h3 {
  margin-top: 0;
}

.profile .balance {
  font-size: 24px;
  margin-bottom: 0;
}

  .profile .balance span {
    font-size: 36px;
    font-weight: bold;
  }

.profile .contact-info dt,
.profile .contact-info dd {
  line-height: 1.6;
}

.profile .contact-info dd {
  margin-bottom: 10px;
}

  .profile .contact-info dd:last-child {
    margin-bottom: 0;
  }

.profile .profile-info {
  float: left;
  width: 100%;
}

  .profile .profile-info p {
    line-height: 1.6;
  }

.profile .profile-tags {
  float: left;
  width: 100%;
}

  .profile .profile-tags .bootstrap-tagsinput {
    border: none;
  }

    .profile .profile-tags .bootstrap-tagsinput .tag:first-child {
      margin-left: 0;
    }

.profile .social {
  float: left;
  width: 100%;
}

  .profile .social dt {
    text-align: left;
    margin-bottom: 10px;
  }

    .profile .social dt:last-child {
      margin-bottom: 0;
    }

    .profile .social dt i {
      margin-right: 10px;
    }
/* -----------------------------------------
    Invoice page
----------------------------------------- */
.invoice .panel-body {
  padding: 40px;
}
/* -----------------------------------------
   Custom progressbar
----------------------------------------- */
.custom-progressbar {
  display: inline-block;
  position: relative;
}

  .custom-progressbar svg {
    display: block;
    width: 100%;
  }

  .custom-progressbar .percent,
  .custom-progressbar .description {
    display: inline-block;
    width: 100%;
    position: absolute;
  }

  .custom-progressbar .percent {
    top: 50%;
    left: 0;
    margin-top: -55px;
    font-size: 64px;
  }

    .custom-progressbar .percent span {
      font-size: 24px;
      padding-top: 10px;
      vertical-align: text-top;
      display: inline-block;
    }

  .custom-progressbar .description {
    top: 50%;
    left: 0;
    margin-top: 20px;
    font-size: 20px;
    text-transform: uppercase;
  }

  .custom-progressbar.blue .percent,
  .custom-progressbar.blue .description {
    color: #0a97b9;
  }

  .custom-progressbar.red .percent,
  .custom-progressbar.red .description {
    color: #c04b59;
  }

  .custom-progressbar.yellow .percent,
  .custom-progressbar.yellow .description {
    color: #d69840;
  }

  .custom-progressbar.green .percent,
  .custom-progressbar.green .description {
    color: #5cb85c;
  }

.custom-progressbar-legend {
  margin-top: 50px;
}

  .custom-progressbar-legend p {
    margin-bottom: 5px;
  }
/* -----------------------------------------
   Weather
----------------------------------------- */
.weather {
  text-align: center;
  margin-top: -42px;
  background-repeat: no-repeat;
  background-position: center;
  background-origin: content-box;
}

  .weather .degree {
    font-size: 64px;
    background-color: rgba(255, 255, 255, 0.7);
  }

  .weather .location {
    font-size: 24px;
    background-color: rgba(255, 255, 255, 0.7);
  }

  .weather .icon {
    background-color: rgba(255, 255, 255, 0.7);
  }
/* -----------------------------------------
   Recent comments
----------------------------------------- */
.recent-comments {
  margin-bottom: 0;
}

  .recent-comments li {
    border-radius: 0;
    border-top-width: 0;
    border-left-width: 4px;
    border-bottom-width: 0;
    border-right-width: 0;
    margin-bottom: 10px;
    background-color: #f2f2f2;
  }

    .recent-comments li.comment-success {
      border-left-color: #53c077;
    }

    .recent-comments li.comment-danger {
      border-left-color: #da557b;
    }

    .recent-comments li.comment-warning {
      border-left-color: #f38231;
    }

    .recent-comments li.comment-info {
      border-left-color: #22b7bb;
    }

    .recent-comments li .avatar img {
      border-radius: 50%;
    }

    .recent-comments li p {
      margin-top: 2px;
    }
/* -----------------------------------------
   Gallery page
----------------------------------------- */
div.gallery-upload {
  overflow: hidden;
  position: relative;
}

  div.gallery-upload .dropzone {
    height: 150px;
    min-height: 150px;
    border: 3px dashed #e3e3e3;
  }

    div.gallery-upload .dropzone .dz-message {
      background: none;
    }

      div.gallery-upload .dropzone .dz-message:after {
        content: "\f093";
        top: 50%;
        height: 50px;
        font-size: 64px;
        left: 50%;
        width: 64px;
        height: 64px;
        position: absolute;
        font-family: FontAwesome;
        margin-left: -32px;
        margin-top: -45px;
        color: #777777;
      }
/* -----------------------------------------
   Responsive media queries
----------------------------------------- */
/* -----------------------------------------
   Big screens
----------------------------------------- */
/* -----------------------------------------
   Desktops
----------------------------------------- */
@media all and (max-width: 1366px) {
  .custom-progressbar-legend {
    display: none;
  }
}

@media all and (max-width: 1280px) {
  /*------------- Sparks in panel -------------*/
  .panel .spark {
    margin-left: 0;
  }
  /*------------- Custom progressbar -------------*/
  #customer-exp {
    width: 244px !important;
  }

  .progressbar-stats-1 .stats .stats-number,
  .progressbar-stats-1 .stats i {
    font-size: 32px;
  }

  #sales-goal {
    width: 100% !important;
    height: 100% !important;
  }
}

@media all and (max-width: 1199px) {
  #sidebar.hidden-md {
    display: block !important;
  }
}
/* -----------------------------------------
   Ipad 3/4
----------------------------------------- */
@media all and (max-width: 1024px) {
  .panel .spark {
    margin-left: auto;
    display: inline-block;
    width: 100%;
  }

  #sidebar.hidden-md {
    display: none !important;
  }

  .page-content.sidebar-page {
    margin-left: 0;
  }
}
/* -----------------------------------------
   Most Tablets and some phones
----------------------------------------- */
@media all and (max-width: 991px) {
  /* ------------------ Page header --------------------*/
  .page-content #page-header .page-header {
    width: 100%;
    text-align: center;
    margin-bottom: 0;
  }

  .page-content #page-header .header-stats {
    text-align: center;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .navbar-brand .logo-sm {
    display: none;
  }

  #sales-goal {
    float: none !important;
    width: 150px !important;
    height: 150px !important;
  }
  /* ------------------ Forms --------------------*/
  .form-group .row .input-icon,
  .form-group .row .input-group {
    margin-bottom: 10px;
  }
  /* ------------------ Dual list --------------------*/
  .bootstrap-duallistbox-container .box2 {
    margin-top: 10px;
  }
  /* ------------------ Datatables --------------------*/
  .table-responsive .dataTables_wrapper {
    padding-top: 10px;
  }

  .dataTable {
    margin-bottom: 15px;
  }

  .dataTables_wrapper .DTTT.btn-group {
    text-align: center;
  }

  .dataTables_wrapper .dataTables_length {
    text-align: center;
  }

    .dataTables_wrapper .dataTables_length label {
      float: none;
      margin-bottom: 0;
    }

  .dataTables_wrapper .dataTables_filter {
    text-align: center;
    margin-top: 10px;
  }

    .dataTables_wrapper .dataTables_filter label {
      float: none;
      margin-bottom: 0;
    }

  .dataTables_wrapper .dataTables_info {
    float: none;
    text-align: center;
    margin-bottom: 0;
  }

  .dataTables_wrapper .dataTables_paginate {
    float: none;
    text-align: center;
    margin-top: 10px;
  }

    .dataTables_wrapper .dataTables_paginate .pagination {
      float: none;
    }
  /* ------------------ Profile --------------------*/
  .profile-name {
    text-align: center;
    margin-bottom: 10px;
  }
}
/* -----------------------------------------
   Most phones
----------------------------------------- */
@media all and (max-width: 767px) {
  /* ------------------ Navbar --------------------*/
  .page-navbar .navbar-brand {
    width: 60px;
    text-align: center;
  }

    .page-navbar .navbar-brand img {
      display: inline-block;
    }

  #header .navbar-nav {
    margin: 0;
    height: 50px;
    float: left;
  }

    #header .navbar-nav.navbar-right {
      float: right;
    }

    #header .navbar-nav li {
      display: inline-block;
    }

      #header .navbar-nav li.open .dropdown-menu {
        position: absolute;
        background-color: #ffffff;
        float: left;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.175);
      }

        #header .navbar-nav li.open .dropdown-menu li {
          display: block;
        }
  /*------------- Sparks in panel -------------*/
  .panel .spark {
    margin-left: 20px;
    float: left;
  }
  /* ------------------ Center modal --------------------*/
  .modal-dialog-center {
    width: 100%;
  }
  /* ------------------ Email app --------------------*/
  #email-content .email-wrapper .email-toolbar .email-pager {
    width: 100%;
    padding-left: 0;
    margin-top: 15px;
    text-align: center;
    display: inline-block;
  }

  #email-content .email-wrapper .email-toolbar div[role=toolbar] {
    width: 100%;
    float: none !important;
    display: inline-block;
    text-align: center;
  }
}
/* -----------------------------------------
   Small devices
----------------------------------------- */
@media all and (max-width: 640px) {
  /* ------------------ Navbar --------------------*/
  .page-content #page-header .header-stats {
    display: none;
  }
  /* ------------------ Login and error --------------------*/
  .login-page .login-panel,
  .error-page .error-panel {
    margin-top: 50px;
  }
  /* ------------------ Full calendar --------------------*/
  .fc-toolbar .fc-left,
  .fc-toolbar .fc-center,
  .fc-toolbar .fc-right {
    float: left;
    text-align: center;
    width: 100%;
    padding: 0;
  }

  .fc-toolbar .fc--left {
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #e3e3e3;
  }

  .fc-toolbar .fc-center {
    padding: 5px 0;
  }

  .fc-toolbar .fc-right {
    padding-bottom: 10px;
    padding-top: 10px;
    border-top: 1px solid #e3e3e3;
  }
}
/* -----------------------------------------
   Small devices most phones
----------------------------------------- */
@media all and (max-width: 500px) {
  /* ------------------ Header --------------------*/
  .page-navbar .navbar-nav > li > a {
    padding: 15px;
  }

  .page-navbar .navbar-brand {
    display: none;
  }
  /* ------------------ Weather --------------------*/
  .panel.mb0 {
    margin-bottom: 20px !important;
  }

  .weather h5 {
    margin-bottom: 10px;
    display: inline-block;
    width: 100%;
  }
  /* ------------------ Footer --------------------*/
  #footer {
    text-align: center;
    height: 70px;
  }

    #footer .pull-left,
    #footer .pull-right {
      float: none !important;
    }
  /* ------------------ Minor --------------------*/
  .dropzone {
    overflow: hidden;
  }

  .md-editor .btn-group {
    margin-bottom: 5px;
  }
  /* ------------------ Invoice --------------------*/
  .invoice-logo {
    text-align: center;
    margin-bottom: 10px;
  }

  .invoice-from ul {
    text-align: center;
  }
  /* ------------------ Error page --------------------*/
  .error-page > .error-container {
    width: 380px;
  }
}
/* -----------------------------------------
   Small devices old phones
----------------------------------------- */
@media all and (max-width: 360px) {
  /* ------------------ Error page --------------------*/
  .error-page > .error-container {
    width: 300px;
  }
}
/* -----------------------------------------
   Crapy phones very old
----------------------------------------- */
@media all and (max-width: 320px) {
  /* ------------------ Navbar --------------------*/
  .page-navbar .navbar-no-collapse {
    padding-left: 5px;
    padding-right: 5px;
  }
  /* ------------------ Login --------------------*/
  .login-page > .login-container {
    width: 300px;
  }
}
/* -----------------------------------------
   Print styles
----------------------------------------- */
@media print {
  .row.printable {
    display: block !important;
  }

  body {
    background: #fff;
    margin: 0;
    padding: 0;
  }

  .page-content,
  .row {
    margin: 0 !important;
    padding: 0 !important;
  }

  .panel {
    box-shadow: none;
  }

    .panel .panel-heading {
      background: none;
      border: none;
      box-shadow: none;
    }

    .panel .content {
      padding-left: 0;
      padding-right: 0;
      border: none;
    }

  #header,
  #sidebar,
  #page-header,
  #footer {
    display: none;
  }
}

span, label {
  font-weight: 400;
}

/* Ajuste no white-space do multi-select para o dropdown do primefaces ser responsivo para desktop e mobile */
li.ui-multiselect-item.ui-corner-all {
  white-space: normal !important;
}

[form-item] {
  margin-bottom: 1.3rem!important;
}

label[required]::after {
  content: " *";
  color: var(--bs-red);
}

/* Ajuste no dropdown do multi-select para ser responsivo para desktop e mobile*/

/* comboClientes */
@media(max-width: 415px) {
  ss-multiselect-dropdown#comboClientes ul.dropdown-menu {
    width: 340px !important;
  }

  ss-multiselect-dropdown#comboClientes li.dropdown-item > a > span {
    display: inline-grid;
    width: 275px;
    white-space: normal;
  }

  @media(max-width: 375px) {
    ss-multiselect-dropdown#comboClientes ul.dropdown-menu {
      width: 310px !important;
    }

    ss-multiselect-dropdown#comboClientes li.dropdown-item > a > span {
      display: inline-grid;
      width: 240px;
      white-space: normal;
    }
  }

  @media(max-width: 320px) {
    ss-multiselect-dropdown#comboClientes ul.dropdown-menu {
      width: 275px !important;
    }

    ss-multiselect-dropdown#comboClientes li.dropdown-item > a > span {
      display: inline-grid;
      width: 190px;
      white-space: normal;
    }
  }
}

/* comboPrazo */
ss-multiselect-dropdown#comboPrazos ul.dropdown-menu {
  width: 340px !important;
}

ss-multiselect-dropdown ul.dropdown-menu {
  margin-top: 0px !important;
}


@media(max-width: 415px) {
  ss-multiselect-dropdown#comboPrazos ul.dropdown-menu {
    width: 340px !important;
  }

  @media(max-width: 375px) {
    ss-multiselect-dropdown#comboPrazos ul.dropdown-menu {
      width: 310px !important;
    }
  }
  
  @media(max-width: 320px) {
    ss-multiselect-dropdown#comboPrazos ul.dropdown-menu {
      width: 265px !important;
    }
    
  }
}

span, label{
  font-size: 1rem !important;
}
