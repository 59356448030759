/* -----------------------------------------
   Custom.css - put your own changes here 
----------------------------------------- */
hr {
    margin-top: 10px;
    margin-bottom: 20px;
}

.pointer {
    cursor: pointer;
}

.form-group {
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.btn-sm i.fa {
    text-rendering: geometricPrecision !important;
    font-size: 13px;
}

.btn-small {
    padding: 4px 10px;
    border-radius: 3px;
}

.btn-bottom-filter {
    margin-top: 0px;
}

.bold {
    font-weight: bold;
}

.mask-input {
    border: 1px solid #cacaca !important;
    font-family: "Quattrocento Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    padding: 6px 12px !important;
}

    .mask-input:hover {
        border-color: #bdbdbd !important;
    }

    .mask-input:focus {
        border-color: #bdbdbd !important;
        outline: 0 !important;
        box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(189, 189, 189, 0.6) !important;
    }

.has-error input.mask-input {
    border-color: #a94442 !important;
}

.column-middle {
  vertical-align: middle !important;
}

@media (min-width: 1200px) {
  .btn-bottom-filter {
        margin-top: 25px;
    }
}

.triangle.dropdown-menu:after,
.triangle.dropdown-menu:before {
  margin-left: 50.5px;
}

input[type=checkbox]:disabled {
  cursor: not-allowed !important;
}
