/* ==========================================================================
Icon packs
========================================================================== */
@font-face {
  font-family: 'fontAwesome';
  src: url('../../fonts/fontawesome-webfont.eot?v=4.1.0');
  src: url('../../fonts/fontawesome-webfont.eot?#iefix&v=4.1.0') format('embedded-opentype'), 
    url('../../fonts/fontawesome-webfont.woff?v=4.1.0') format('woff'), 
    url('../../fonts/fontawesome-webfont.ttf?v=4.1.0') format('truetype'), 
    url('../../fonts/fontawesome-webfont.svg?v=4.1.0#fontawesomeregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "linea";
  src:url("../../fonts/linea.eot");
  src:url("../../fonts/linea.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/linea.woff") format("woff"),
    url("../../fonts/linea.ttf") format("truetype"),
    url("../../fonts/linea.svg#linea") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="l-"]:before,
[class*=" l-"]:before {
  font-family: "linea" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  vertical-align: text-top;
}

[class^="glyphicon"]:before,
[class*=" glyphicon"]:before {
  line-height: 1;
  vertical-align: middle;
}

.glyphicon { top: 0 !important;}

/* ------------------ Demo styles ( delete it in production ) --------------------*/

.row.icons .panel [class^="col-lg-"] {
  margin-bottom: 10px;
}
.row.icons i {
  font-size: 20px;
  margin-right: 10px;
}

/* ------------------ Font awesome --------------------*/

.fa {
  display: inline-block;
  font-family: fontAwesome;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* ------------------ Spin icon --------------------*/
.icon-spin {
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
  }
}
@-ms-keyframes spin {
  0% {
    -ms-transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(359deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

/* Font awesome */
/* makes the font 33% larger relative to the icon container */
.fa-lg {
  font-size: 1.33333333em;
  line-height: 0.75em;
  vertical-align: -15%;
}
.fa-2x {
  font-size: 2em;
}
.fa-3x {
  font-size: 3em;
}
.fa-4x {
  font-size: 4em;
}
.fa-5x {
  font-size: 5em;
}
.fa-fw {
  width: 1.28571429em;
  text-align: center;
}
.fa-ul {
  padding-left: 0;
  margin-left: 2.14285714em;
  list-style-type: none;
}
.fa-ul > li {
  position: relative;
}
.fa-li {
  position: absolute;
  left: -2.14285714em;
  width: 2.14285714em;
  top: 0.14285714em;
  text-align: center;
}
.fa-li.fa-lg {
  left: -1.85714286em;
}
.fa-border {
  padding: .2em .25em .15em;
  border: solid 0.08em #eeeeee;
  border-radius: .1em;
}
.pull-right {
  float: right;
}
.pull-left {
  float: left;
}
.fa.pull-left {
  margin-right: .3em;
}
.fa.pull-right {
  margin-left: .3em;
}
.fa-spin {
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
.fa-rotate-90 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.fa-rotate-180 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.fa-rotate-270 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
.fa-flip-horizontal {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1);
  -webkit-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}
.fa-flip-vertical {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1);
  -webkit-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  transform: scale(1, -1);
}
:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical {
  -webkit-filter: none;
  filter: none;
}
.fa-stack {
  position: relative;
  display: inline-block;
  width: 2em;
  height: 2em;
  line-height: 2em;
  vertical-align: middle;
}
.fa-stack-1x,
.fa-stack-2x {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
}
.fa-stack-1x {
  line-height: inherit;
}
.fa-stack-2x {
  font-size: 2em;
}
.fa-inverse {
  color: #ffffff;
}
/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
   readers do not read off random characters that represent icons */
.fa-glass:before {
  content: "\f000";
}
.fa-music:before {
  content: "\f001";
}
.fa-search:before {
  content: "\f002";
}
.fa-envelope-o:before {
  content: "\f003";
}
.fa-heart:before {
  content: "\f004";
}
.fa-star:before {
  content: "\f005";
}
.fa-star-o:before {
  content: "\f006";
}
.fa-user:before {
  content: "\f007";
}
.fa-film:before {
  content: "\f008";
}
.fa-th-large:before {
  content: "\f009";
}
.fa-th:before {
  content: "\f00a";
}
.fa-th-list:before {
  content: "\f00b";
}
.fa-check:before {
  content: "\f00c";
}
.fa-remove:before,
.fa-close:before,
.fa-times:before {
  content: "\f00d";
}
.fa-search-plus:before {
  content: "\f00e";
}
.fa-search-minus:before {
  content: "\f010";
}
.fa-power-off:before {
  content: "\f011";
}
.fa-signal:before {
  content: "\f012";
}
.fa-gear:before,
.fa-cog:before {
  content: "\f013";
}
.fa-trash-o:before {
  content: "\f014";
}
.fa-home:before {
  content: "\f015";
}
.fa-file-o:before {
  content: "\f016";
}
.fa-clock-o:before {
  content: "\f017";
}
.fa-road:before {
  content: "\f018";
}
.fa-download:before {
  content: "\f019";
}
.fa-arrow-circle-o-down:before {
  content: "\f01a";
}
.fa-arrow-circle-o-up:before {
  content: "\f01b";
}
.fa-inbox:before {
  content: "\f01c";
}
.fa-play-circle-o:before {
  content: "\f01d";
}
.fa-rotate-right:before,
.fa-repeat:before {
  content: "\f01e";
}
.fa-refresh:before {
  content: "\f021";
}
.fa-list-alt:before {
  content: "\f022";
}
.fa-lock:before {
  content: "\f023";
}
.fa-flag:before {
  content: "\f024";
}
.fa-headphones:before {
  content: "\f025";
}
.fa-volume-off:before {
  content: "\f026";
}
.fa-volume-down:before {
  content: "\f027";
}
.fa-volume-up:before {
  content: "\f028";
}
.fa-qrcode:before {
  content: "\f029";
}
.fa-barcode:before {
  content: "\f02a";
}
.fa-tag:before {
  content: "\f02b";
}
.fa-tags:before {
  content: "\f02c";
}
.fa-book:before {
  content: "\f02d";
}
.fa-bookmark:before {
  content: "\f02e";
}
.fa-print:before {
  content: "\f02f";
}
.fa-camera:before {
  content: "\f030";
}
.fa-font:before {
  content: "\f031";
}
.fa-bold:before {
  content: "\f032";
}
.fa-italic:before {
  content: "\f033";
}
.fa-text-height:before {
  content: "\f034";
}
.fa-text-width:before {
  content: "\f035";
}
.fa-align-left:before {
  content: "\f036";
}
.fa-align-center:before {
  content: "\f037";
}
.fa-align-right:before {
  content: "\f038";
}
.fa-align-justify:before {
  content: "\f039";
}
.fa-list:before {
  content: "\f03a";
}
.fa-dedent:before,
.fa-outdent:before {
  content: "\f03b";
}
.fa-indent:before {
  content: "\f03c";
}
.fa-video-camera:before {
  content: "\f03d";
}
.fa-photo:before,
.fa-image:before,
.fa-picture-o:before {
  content: "\f03e";
}
.fa-pencil:before {
  content: "\f040";
}
.fa-map-marker:before {
  content: "\f041";
}
.fa-adjust:before {
  content: "\f042";
}
.fa-tint:before {
  content: "\f043";
}
.fa-edit:before,
.fa-pencil-square-o:before {
  content: "\f044";
}
.fa-share-square-o:before {
  content: "\f045";
}
.fa-check-square-o:before {
  content: "\f046";
}
.fa-arrows:before {
  content: "\f047";
}
.fa-step-backward:before {
  content: "\f048";
}
.fa-fast-backward:before {
  content: "\f049";
}
.fa-backward:before {
  content: "\f04a";
}
.fa-play:before {
  content: "\f04b";
}
.fa-pause:before {
  content: "\f04c";
}
.fa-stop:before {
  content: "\f04d";
}
.fa-forward:before {
  content: "\f04e";
}
.fa-fast-forward:before {
  content: "\f050";
}
.fa-step-forward:before {
  content: "\f051";
}
.fa-eject:before {
  content: "\f052";
}
.fa-chevron-left:before {
  content: "\f053";
}
.fa-chevron-right:before {
  content: "\f054";
}
.fa-plus-circle:before {
  content: "\f055";
}
.fa-minus-circle:before {
  content: "\f056";
}
.fa-times-circle:before {
  content: "\f057";
}
.fa-check-circle:before {
  content: "\f058";
}
.fa-question-circle:before {
  content: "\f059";
}
.fa-info-circle:before {
  content: "\f05a";
}
.fa-crosshairs:before {
  content: "\f05b";
}
.fa-times-circle-o:before {
  content: "\f05c";
}
.fa-check-circle-o:before {
  content: "\f05d";
}
.fa-ban:before {
  content: "\f05e";
}
.fa-arrow-left:before {
  content: "\f060";
}
.fa-arrow-right:before {
  content: "\f061";
}
.fa-arrow-up:before {
  content: "\f062";
}
.fa-arrow-down:before {
  content: "\f063";
}
.fa-mail-forward:before,
.fa-share:before {
  content: "\f064";
}
.fa-expand:before {
  content: "\f065";
}
.fa-compress:before {
  content: "\f066";
}
.fa-plus:before {
  content: "\f067";
}
.fa-minus:before {
  content: "\f068";
}
.fa-asterisk:before {
  content: "\f069";
}
.fa-exclamation-circle:before {
  content: "\f06a";
}
.fa-gift:before {
  content: "\f06b";
}
.fa-leaf:before {
  content: "\f06c";
}
.fa-fire:before {
  content: "\f06d";
}
.fa-eye:before {
  content: "\f06e";
}
.fa-eye-slash:before {
  content: "\f070";
}
.fa-warning:before,
.fa-exclamation-triangle:before {
  content: "\f071";
}
.fa-plane:before {
  content: "\f072";
}
.fa-calendar:before {
  content: "\f073";
}
.fa-random:before {
  content: "\f074";
}
.fa-comment:before {
  content: "\f075";
}
.fa-magnet:before {
  content: "\f076";
}
.fa-chevron-up:before {
  content: "\f077";
}
.fa-chevron-down:before {
  content: "\f078";
}
.fa-retweet:before {
  content: "\f079";
}
.fa-shopping-cart:before {
  content: "\f07a";
}
.fa-folder:before {
  content: "\f07b";
}
.fa-folder-open:before {
  content: "\f07c";
}
.fa-arrows-v:before {
  content: "\f07d";
}
.fa-arrows-h:before {
  content: "\f07e";
}
.fa-bar-chart-o:before,
.fa-bar-chart:before {
  content: "\f080";
}
.fa-twitter-square:before {
  content: "\f081";
}
.fa-facebook-square:before {
  content: "\f082";
}
.fa-camera-retro:before {
  content: "\f083";
}
.fa-key:before {
  content: "\f084";
}
.fa-gears:before,
.fa-cogs:before {
  content: "\f085";
}
.fa-comments:before {
  content: "\f086";
}
.fa-thumbs-o-up:before {
  content: "\f087";
}
.fa-thumbs-o-down:before {
  content: "\f088";
}
.fa-star-half:before {
  content: "\f089";
}
.fa-heart-o:before {
  content: "\f08a";
}
.fa-sign-out:before {
  content: "\f08b";
}
.fa-linkedin-square:before {
  content: "\f08c";
}
.fa-thumb-tack:before {
  content: "\f08d";
}
.fa-external-link:before {
  content: "\f08e";
}
.fa-sign-in:before {
  content: "\f090";
}
.fa-trophy:before {
  content: "\f091";
}
.fa-github-square:before {
  content: "\f092";
}
.fa-upload:before {
  content: "\f093";
}
.fa-lemon-o:before {
  content: "\f094";
}
.fa-phone:before {
  content: "\f095";
}
.fa-square-o:before {
  content: "\f096";
}
.fa-bookmark-o:before {
  content: "\f097";
}
.fa-phone-square:before {
  content: "\f098";
}
.fa-twitter:before {
  content: "\f099";
}
.fa-facebook-f:before,
.fa-facebook:before {
  content: "\f09a";
}
.fa-github:before {
  content: "\f09b";
}
.fa-unlock:before {
  content: "\f09c";
}
.fa-credit-card:before {
  content: "\f09d";
}
.fa-feed:before,
.fa-rss:before {
  content: "\f09e";
}
.fa-hdd-o:before {
  content: "\f0a0";
}
.fa-bullhorn:before {
  content: "\f0a1";
}
.fa-bell:before {
  content: "\f0f3";
}
.fa-certificate:before {
  content: "\f0a3";
}
.fa-hand-o-right:before {
  content: "\f0a4";
}
.fa-hand-o-left:before {
  content: "\f0a5";
}
.fa-hand-o-up:before {
  content: "\f0a6";
}
.fa-hand-o-down:before {
  content: "\f0a7";
}
.fa-arrow-circle-left:before {
  content: "\f0a8";
}
.fa-arrow-circle-right:before {
  content: "\f0a9";
}
.fa-arrow-circle-up:before {
  content: "\f0aa";
}
.fa-arrow-circle-down:before {
  content: "\f0ab";
}
.fa-globe:before {
  content: "\f0ac";
}
.fa-wrench:before {
  content: "\f0ad";
}
.fa-tasks:before {
  content: "\f0ae";
}
.fa-filter:before {
  content: "\f0b0";
}
.fa-briefcase:before {
  content: "\f0b1";
}
.fa-arrows-alt:before {
  content: "\f0b2";
}
.fa-group:before,
.fa-users:before {
  content: "\f0c0";
}
.fa-chain:before,
.fa-link:before {
  content: "\f0c1";
}
.fa-cloud:before {
  content: "\f0c2";
}
.fa-flask:before {
  content: "\f0c3";
}
.fa-cut:before,
.fa-scissors:before {
  content: "\f0c4";
}
.fa-copy:before,
.fa-files-o:before {
  content: "\f0c5";
}
.fa-paperclip:before {
  content: "\f0c6";
}
.fa-save:before,
.fa-floppy-o:before {
  content: "\f0c7";
}
.fa-square:before {
  content: "\f0c8";
}
.fa-navicon:before,
.fa-reorder:before,
.fa-bars:before {
  content: "\f0c9";
}
.fa-list-ul:before {
  content: "\f0ca";
}
.fa-list-ol:before {
  content: "\f0cb";
}
.fa-strikethrough:before {
  content: "\f0cc";
}
.fa-underline:before {
  content: "\f0cd";
}
.fa-table:before {
  content: "\f0ce";
}
.fa-magic:before {
  content: "\f0d0";
}
.fa-truck:before {
  content: "\f0d1";
}
.fa-pinterest:before {
  content: "\f0d2";
}
.fa-pinterest-square:before {
  content: "\f0d3";
}
.fa-google-plus-square:before {
  content: "\f0d4";
}
.fa-google-plus:before {
  content: "\f0d5";
}
.fa-money:before {
  content: "\f0d6";
}
.fa-caret-down:before {
  content: "\f0d7";
}
.fa-caret-up:before {
  content: "\f0d8";
}
.fa-caret-left:before {
  content: "\f0d9";
}
.fa-caret-right:before {
  content: "\f0da";
}
.fa-columns:before {
  content: "\f0db";
}
.fa-unsorted:before,
.fa-sort:before {
  content: "\f0dc";
}
.fa-sort-down:before,
.fa-sort-desc:before {
  content: "\f0dd";
}
.fa-sort-up:before,
.fa-sort-asc:before {
  content: "\f0de";
}
.fa-envelope:before {
  content: "\f0e0";
}
.fa-linkedin:before {
  content: "\f0e1";
}
.fa-rotate-left:before,
.fa-undo:before {
  content: "\f0e2";
}
.fa-legal:before,
.fa-gavel:before {
  content: "\f0e3";
}
.fa-dashboard:before,
.fa-tachometer:before {
  content: "\f0e4";
}
.fa-comment-o:before {
  content: "\f0e5";
}
.fa-comments-o:before {
  content: "\f0e6";
}
.fa-flash:before,
.fa-bolt:before {
  content: "\f0e7";
}
.fa-sitemap:before {
  content: "\f0e8";
}
.fa-umbrella:before {
  content: "\f0e9";
}
.fa-paste:before,
.fa-clipboard:before {
  content: "\f0ea";
}
.fa-lightbulb-o:before {
  content: "\f0eb";
}
.fa-exchange:before {
  content: "\f0ec";
}
.fa-cloud-download:before {
  content: "\f0ed";
}
.fa-cloud-upload:before {
  content: "\f0ee";
}
.fa-user-md:before {
  content: "\f0f0";
}
.fa-stethoscope:before {
  content: "\f0f1";
}
.fa-suitcase:before {
  content: "\f0f2";
}
.fa-bell-o:before {
  content: "\f0a2";
}
.fa-coffee:before {
  content: "\f0f4";
}
.fa-cutlery:before {
  content: "\f0f5";
}
.fa-file-text-o:before {
  content: "\f0f6";
}
.fa-building-o:before {
  content: "\f0f7";
}
.fa-hospital-o:before {
  content: "\f0f8";
}
.fa-ambulance:before {
  content: "\f0f9";
}
.fa-medkit:before {
  content: "\f0fa";
}
.fa-fighter-jet:before {
  content: "\f0fb";
}
.fa-beer:before {
  content: "\f0fc";
}
.fa-h-square:before {
  content: "\f0fd";
}
.fa-plus-square:before {
  content: "\f0fe";
}
.fa-angle-double-left:before {
  content: "\f100";
}
.fa-angle-double-right:before {
  content: "\f101";
}
.fa-angle-double-up:before {
  content: "\f102";
}
.fa-angle-double-down:before {
  content: "\f103";
}
.fa-angle-left:before {
  content: "\f104";
}
.fa-angle-right:before {
  content: "\f105";
}
.fa-angle-up:before {
  content: "\f106";
}
.fa-angle-down:before {
  content: "\f107";
}
.fa-desktop:before {
  content: "\f108";
}
.fa-laptop:before {
  content: "\f109";
}
.fa-tablet:before {
  content: "\f10a";
}
.fa-mobile-phone:before,
.fa-mobile:before {
  content: "\f10b";
}
.fa-circle-o:before {
  content: "\f10c";
}
.fa-quote-left:before {
  content: "\f10d";
}
.fa-quote-right:before {
  content: "\f10e";
}
.fa-spinner:before {
  content: "\f110";
}
.fa-circle:before {
  content: "\f111";
}
.fa-mail-reply:before,
.fa-reply:before {
  content: "\f112";
}
.fa-github-alt:before {
  content: "\f113";
}
.fa-folder-o:before {
  content: "\f114";
}
.fa-folder-open-o:before {
  content: "\f115";
}
.fa-smile-o:before {
  content: "\f118";
}
.fa-frown-o:before {
  content: "\f119";
}
.fa-meh-o:before {
  content: "\f11a";
}
.fa-gamepad:before {
  content: "\f11b";
}
.fa-keyboard-o:before {
  content: "\f11c";
}
.fa-flag-o:before {
  content: "\f11d";
}
.fa-flag-checkered:before {
  content: "\f11e";
}
.fa-terminal:before {
  content: "\f120";
}
.fa-code:before {
  content: "\f121";
}
.fa-mail-reply-all:before,
.fa-reply-all:before {
  content: "\f122";
}
.fa-star-half-empty:before,
.fa-star-half-full:before,
.fa-star-half-o:before {
  content: "\f123";
}
.fa-location-arrow:before {
  content: "\f124";
}
.fa-crop:before {
  content: "\f125";
}
.fa-code-fork:before {
  content: "\f126";
}
.fa-unlink:before,
.fa-chain-broken:before {
  content: "\f127";
}
.fa-question:before {
  content: "\f128";
}
.fa-info:before {
  content: "\f129";
}
.fa-exclamation:before {
  content: "\f12a";
}
.fa-superscript:before {
  content: "\f12b";
}
.fa-subscript:before {
  content: "\f12c";
}
.fa-eraser:before {
  content: "\f12d";
}
.fa-puzzle-piece:before {
  content: "\f12e";
}
.fa-microphone:before {
  content: "\f130";
}
.fa-microphone-slash:before {
  content: "\f131";
}
.fa-shield:before {
  content: "\f132";
}
.fa-calendar-o:before {
  content: "\f133";
}
.fa-fire-extinguisher:before {
  content: "\f134";
}
.fa-rocket:before {
  content: "\f135";
}
.fa-maxcdn:before {
  content: "\f136";
}
.fa-chevron-circle-left:before {
  content: "\f137";
}
.fa-chevron-circle-right:before {
  content: "\f138";
}
.fa-chevron-circle-up:before {
  content: "\f139";
}
.fa-chevron-circle-down:before {
  content: "\f13a";
}
.fa-html5:before {
  content: "\f13b";
}
.fa-css3:before {
  content: "\f13c";
}
.fa-anchor:before {
  content: "\f13d";
}
.fa-unlock-alt:before {
  content: "\f13e";
}
.fa-bullseye:before {
  content: "\f140";
}
.fa-ellipsis-h:before {
  content: "\f141";
}
.fa-ellipsis-v:before {
  content: "\f142";
}
.fa-rss-square:before {
  content: "\f143";
}
.fa-play-circle:before {
  content: "\f144";
}
.fa-ticket:before {
  content: "\f145";
}
.fa-minus-square:before {
  content: "\f146";
}
.fa-minus-square-o:before {
  content: "\f147";
}
.fa-level-up:before {
  content: "\f148";
}
.fa-level-down:before {
  content: "\f149";
}
.fa-check-square:before {
  content: "\f14a";
}
.fa-pencil-square:before {
  content: "\f14b";
}
.fa-external-link-square:before {
  content: "\f14c";
}
.fa-share-square:before {
  content: "\f14d";
}
.fa-compass:before {
  content: "\f14e";
}
.fa-toggle-down:before,
.fa-caret-square-o-down:before {
  content: "\f150";
}
.fa-toggle-up:before,
.fa-caret-square-o-up:before {
  content: "\f151";
}
.fa-toggle-right:before,
.fa-caret-square-o-right:before {
  content: "\f152";
}
.fa-euro:before,
.fa-eur:before {
  content: "\f153";
}
.fa-gbp:before {
  content: "\f154";
}
.fa-dollar:before,
.fa-usd:before {
  content: "\f155";
}
.fa-rupee:before,
.fa-inr:before {
  content: "\f156";
}
.fa-cny:before,
.fa-rmb:before,
.fa-yen:before,
.fa-jpy:before {
  content: "\f157";
}
.fa-ruble:before,
.fa-rouble:before,
.fa-rub:before {
  content: "\f158";
}
.fa-won:before,
.fa-krw:before {
  content: "\f159";
}
.fa-bitcoin:before,
.fa-btc:before {
  content: "\f15a";
}
.fa-file:before {
  content: "\f15b";
}
.fa-file-text:before {
  content: "\f15c";
}
.fa-sort-alpha-asc:before {
  content: "\f15d";
}
.fa-sort-alpha-desc:before {
  content: "\f15e";
}
.fa-sort-amount-asc:before {
  content: "\f160";
}
.fa-sort-amount-desc:before {
  content: "\f161";
}
.fa-sort-numeric-asc:before {
  content: "\f162";
}
.fa-sort-numeric-desc:before {
  content: "\f163";
}
.fa-thumbs-up:before {
  content: "\f164";
}
.fa-thumbs-down:before {
  content: "\f165";
}
.fa-youtube-square:before {
  content: "\f166";
}
.fa-youtube:before {
  content: "\f167";
}
.fa-xing:before {
  content: "\f168";
}
.fa-xing-square:before {
  content: "\f169";
}
.fa-youtube-play:before {
  content: "\f16a";
}
.fa-dropbox:before {
  content: "\f16b";
}
.fa-stack-overflow:before {
  content: "\f16c";
}
.fa-instagram:before {
  content: "\f16d";
}
.fa-flickr:before {
  content: "\f16e";
}
.fa-adn:before {
  content: "\f170";
}
.fa-bitbucket:before {
  content: "\f171";
}
.fa-bitbucket-square:before {
  content: "\f172";
}
.fa-tumblr:before {
  content: "\f173";
}
.fa-tumblr-square:before {
  content: "\f174";
}
.fa-long-arrow-down:before {
  content: "\f175";
}
.fa-long-arrow-up:before {
  content: "\f176";
}
.fa-long-arrow-left:before {
  content: "\f177";
}
.fa-long-arrow-right:before {
  content: "\f178";
}
.fa-apple:before {
  content: "\f179";
}
.fa-windows:before {
  content: "\f17a";
}
.fa-android:before {
  content: "\f17b";
}
.fa-linux:before {
  content: "\f17c";
}
.fa-dribbble:before {
  content: "\f17d";
}
.fa-skype:before {
  content: "\f17e";
}
.fa-foursquare:before {
  content: "\f180";
}
.fa-trello:before {
  content: "\f181";
}
.fa-female:before {
  content: "\f182";
}
.fa-male:before {
  content: "\f183";
}
.fa-gittip:before,
.fa-gratipay:before {
  content: "\f184";
}
.fa-sun-o:before {
  content: "\f185";
}
.fa-moon-o:before {
  content: "\f186";
}
.fa-archive:before {
  content: "\f187";
}
.fa-bug:before {
  content: "\f188";
}
.fa-vk:before {
  content: "\f189";
}
.fa-weibo:before {
  content: "\f18a";
}
.fa-renren:before {
  content: "\f18b";
}
.fa-pagelines:before {
  content: "\f18c";
}
.fa-stack-exchange:before {
  content: "\f18d";
}
.fa-arrow-circle-o-right:before {
  content: "\f18e";
}
.fa-arrow-circle-o-left:before {
  content: "\f190";
}
.fa-toggle-left:before,
.fa-caret-square-o-left:before {
  content: "\f191";
}
.fa-dot-circle-o:before {
  content: "\f192";
}
.fa-wheelchair:before {
  content: "\f193";
}
.fa-vimeo-square:before {
  content: "\f194";
}
.fa-turkish-lira:before,
.fa-try:before {
  content: "\f195";
}
.fa-plus-square-o:before {
  content: "\f196";
}
.fa-space-shuttle:before {
  content: "\f197";
}
.fa-slack:before {
  content: "\f198";
}
.fa-envelope-square:before {
  content: "\f199";
}
.fa-wordpress:before {
  content: "\f19a";
}
.fa-openid:before {
  content: "\f19b";
}
.fa-institution:before,
.fa-bank:before,
.fa-university:before {
  content: "\f19c";
}
.fa-mortar-board:before,
.fa-graduation-cap:before {
  content: "\f19d";
}
.fa-yahoo:before {
  content: "\f19e";
}
.fa-google:before {
  content: "\f1a0";
}
.fa-reddit:before {
  content: "\f1a1";
}
.fa-reddit-square:before {
  content: "\f1a2";
}
.fa-stumbleupon-circle:before {
  content: "\f1a3";
}
.fa-stumbleupon:before {
  content: "\f1a4";
}
.fa-delicious:before {
  content: "\f1a5";
}
.fa-digg:before {
  content: "\f1a6";
}
.fa-pied-piper:before {
  content: "\f1a7";
}
.fa-pied-piper-alt:before {
  content: "\f1a8";
}
.fa-drupal:before {
  content: "\f1a9";
}
.fa-joomla:before {
  content: "\f1aa";
}
.fa-language:before {
  content: "\f1ab";
}
.fa-fax:before {
  content: "\f1ac";
}
.fa-building:before {
  content: "\f1ad";
}
.fa-child:before {
  content: "\f1ae";
}
.fa-paw:before {
  content: "\f1b0";
}
.fa-spoon:before {
  content: "\f1b1";
}
.fa-cube:before {
  content: "\f1b2";
}
.fa-cubes:before {
  content: "\f1b3";
}
.fa-behance:before {
  content: "\f1b4";
}
.fa-behance-square:before {
  content: "\f1b5";
}
.fa-steam:before {
  content: "\f1b6";
}
.fa-steam-square:before {
  content: "\f1b7";
}
.fa-recycle:before {
  content: "\f1b8";
}
.fa-automobile:before,
.fa-car:before {
  content: "\f1b9";
}
.fa-cab:before,
.fa-taxi:before {
  content: "\f1ba";
}
.fa-tree:before {
  content: "\f1bb";
}
.fa-spotify:before {
  content: "\f1bc";
}
.fa-deviantart:before {
  content: "\f1bd";
}
.fa-soundcloud:before {
  content: "\f1be";
}
.fa-database:before {
  content: "\f1c0";
}
.fa-file-pdf-o:before {
  content: "\f1c1";
}
.fa-file-word-o:before {
  content: "\f1c2";
}
.fa-file-excel-o:before {
  content: "\f1c3";
}
.fa-file-powerpoint-o:before {
  content: "\f1c4";
}
.fa-file-photo-o:before,
.fa-file-picture-o:before,
.fa-file-image-o:before {
  content: "\f1c5";
}
.fa-file-zip-o:before,
.fa-file-archive-o:before {
  content: "\f1c6";
}
.fa-file-sound-o:before,
.fa-file-audio-o:before {
  content: "\f1c7";
}
.fa-file-movie-o:before,
.fa-file-video-o:before {
  content: "\f1c8";
}
.fa-file-code-o:before {
  content: "\f1c9";
}
.fa-vine:before {
  content: "\f1ca";
}
.fa-codepen:before {
  content: "\f1cb";
}
.fa-jsfiddle:before {
  content: "\f1cc";
}
.fa-life-bouy:before,
.fa-life-buoy:before,
.fa-life-saver:before,
.fa-support:before,
.fa-life-ring:before {
  content: "\f1cd";
}
.fa-circle-o-notch:before {
  content: "\f1ce";
}
.fa-ra:before,
.fa-rebel:before {
  content: "\f1d0";
}
.fa-ge:before,
.fa-empire:before {
  content: "\f1d1";
}
.fa-git-square:before {
  content: "\f1d2";
}
.fa-git:before {
  content: "\f1d3";
}
.fa-y-combinator-square:before,
.fa-yc-square:before,
.fa-hacker-news:before {
  content: "\f1d4";
}
.fa-tencent-weibo:before {
  content: "\f1d5";
}
.fa-qq:before {
  content: "\f1d6";
}
.fa-wechat:before,
.fa-weixin:before {
  content: "\f1d7";
}
.fa-send:before,
.fa-paper-plane:before {
  content: "\f1d8";
}
.fa-send-o:before,
.fa-paper-plane-o:before {
  content: "\f1d9";
}
.fa-history:before {
  content: "\f1da";
}
.fa-circle-thin:before {
  content: "\f1db";
}
.fa-header:before {
  content: "\f1dc";
}
.fa-paragraph:before {
  content: "\f1dd";
}
.fa-sliders:before {
  content: "\f1de";
}
.fa-share-alt:before {
  content: "\f1e0";
}
.fa-share-alt-square:before {
  content: "\f1e1";
}
.fa-bomb:before {
  content: "\f1e2";
}
.fa-soccer-ball-o:before,
.fa-futbol-o:before {
  content: "\f1e3";
}
.fa-tty:before {
  content: "\f1e4";
}
.fa-binoculars:before {
  content: "\f1e5";
}
.fa-plug:before {
  content: "\f1e6";
}
.fa-slideshare:before {
  content: "\f1e7";
}
.fa-twitch:before {
  content: "\f1e8";
}
.fa-yelp:before {
  content: "\f1e9";
}
.fa-newspaper-o:before {
  content: "\f1ea";
}
.fa-wifi:before {
  content: "\f1eb";
}
.fa-calculator:before {
  content: "\f1ec";
}
.fa-paypal:before {
  content: "\f1ed";
}
.fa-google-wallet:before {
  content: "\f1ee";
}
.fa-cc-visa:before {
  content: "\f1f0";
}
.fa-cc-mastercard:before {
  content: "\f1f1";
}
.fa-cc-discover:before {
  content: "\f1f2";
}
.fa-cc-amex:before {
  content: "\f1f3";
}
.fa-cc-paypal:before {
  content: "\f1f4";
}
.fa-cc-stripe:before {
  content: "\f1f5";
}
.fa-bell-slash:before {
  content: "\f1f6";
}
.fa-bell-slash-o:before {
  content: "\f1f7";
}
.fa-trash:before {
  content: "\f1f8";
}
.fa-copyright:before {
  content: "\f1f9";
}
.fa-at:before {
  content: "\f1fa";
}
.fa-eyedropper:before {
  content: "\f1fb";
}
.fa-paint-brush:before {
  content: "\f1fc";
}
.fa-birthday-cake:before {
  content: "\f1fd";
}
.fa-area-chart:before {
  content: "\f1fe";
}
.fa-pie-chart:before {
  content: "\f200";
}
.fa-line-chart:before {
  content: "\f201";
}
.fa-lastfm:before {
  content: "\f202";
}
.fa-lastfm-square:before {
  content: "\f203";
}
.fa-toggle-off:before {
  content: "\f204";
}
.fa-toggle-on:before {
  content: "\f205";
}
.fa-bicycle:before {
  content: "\f206";
}
.fa-bus:before {
  content: "\f207";
}
.fa-ioxhost:before {
  content: "\f208";
}
.fa-angellist:before {
  content: "\f209";
}
.fa-cc:before {
  content: "\f20a";
}
.fa-shekel:before,
.fa-sheqel:before,
.fa-ils:before {
  content: "\f20b";
}
.fa-meanpath:before {
  content: "\f20c";
}
.fa-buysellads:before {
  content: "\f20d";
}
.fa-connectdevelop:before {
  content: "\f20e";
}
.fa-dashcube:before {
  content: "\f210";
}
.fa-forumbee:before {
  content: "\f211";
}
.fa-leanpub:before {
  content: "\f212";
}
.fa-sellsy:before {
  content: "\f213";
}
.fa-shirtsinbulk:before {
  content: "\f214";
}
.fa-simplybuilt:before {
  content: "\f215";
}
.fa-skyatlas:before {
  content: "\f216";
}
.fa-cart-plus:before {
  content: "\f217";
}
.fa-cart-arrow-down:before {
  content: "\f218";
}
.fa-diamond:before {
  content: "\f219";
}
.fa-ship:before {
  content: "\f21a";
}
.fa-user-secret:before {
  content: "\f21b";
}
.fa-motorcycle:before {
  content: "\f21c";
}
.fa-street-view:before {
  content: "\f21d";
}
.fa-heartbeat:before {
  content: "\f21e";
}
.fa-venus:before {
  content: "\f221";
}
.fa-mars:before {
  content: "\f222";
}
.fa-mercury:before {
  content: "\f223";
}
.fa-intersex:before,
.fa-transgender:before {
  content: "\f224";
}
.fa-transgender-alt:before {
  content: "\f225";
}
.fa-venus-double:before {
  content: "\f226";
}
.fa-mars-double:before {
  content: "\f227";
}
.fa-venus-mars:before {
  content: "\f228";
}
.fa-mars-stroke:before {
  content: "\f229";
}
.fa-mars-stroke-v:before {
  content: "\f22a";
}
.fa-mars-stroke-h:before {
  content: "\f22b";
}
.fa-neuter:before {
  content: "\f22c";
}
.fa-genderless:before {
  content: "\f22d";
}
.fa-facebook-official:before {
  content: "\f230";
}
.fa-pinterest-p:before {
  content: "\f231";
}
.fa-whatsapp:before {
  content: "\f232";
}
.fa-server:before {
  content: "\f233";
}
.fa-user-plus:before {
  content: "\f234";
}
.fa-user-times:before {
  content: "\f235";
}
.fa-hotel:before,
.fa-bed:before {
  content: "\f236";
}
.fa-viacoin:before {
  content: "\f237";
}
.fa-train:before {
  content: "\f238";
}
.fa-subway:before {
  content: "\f239";
}
.fa-medium:before {
  content: "\f23a";
}
.fa-yc:before,
.fa-y-combinator:before {
  content: "\f23b";
}
.fa-optin-monster:before {
  content: "\f23c";
}
.fa-opencart:before {
  content: "\f23d";
}
.fa-expeditedssl:before {
  content: "\f23e";
}
.fa-battery-4:before,
.fa-battery-full:before {
  content: "\f240";
}
.fa-battery-3:before,
.fa-battery-three-quarters:before {
  content: "\f241";
}
.fa-battery-2:before,
.fa-battery-half:before {
  content: "\f242";
}
.fa-battery-1:before,
.fa-battery-quarter:before {
  content: "\f243";
}
.fa-battery-0:before,
.fa-battery-empty:before {
  content: "\f244";
}
.fa-mouse-pointer:before {
  content: "\f245";
}
.fa-i-cursor:before {
  content: "\f246";
}
.fa-object-group:before {
  content: "\f247";
}
.fa-object-ungroup:before {
  content: "\f248";
}
.fa-sticky-note:before {
  content: "\f249";
}
.fa-sticky-note-o:before {
  content: "\f24a";
}
.fa-cc-jcb:before {
  content: "\f24b";
}
.fa-cc-diners-club:before {
  content: "\f24c";
}
.fa-clone:before {
  content: "\f24d";
}
.fa-balance-scale:before {
  content: "\f24e";
}
.fa-hourglass-o:before {
  content: "\f250";
}
.fa-hourglass-1:before,
.fa-hourglass-start:before {
  content: "\f251";
}
.fa-hourglass-2:before,
.fa-hourglass-half:before {
  content: "\f252";
}
.fa-hourglass-3:before,
.fa-hourglass-end:before {
  content: "\f253";
}
.fa-hourglass:before {
  content: "\f254";
}
.fa-hand-grab-o:before,
.fa-hand-rock-o:before {
  content: "\f255";
}
.fa-hand-stop-o:before,
.fa-hand-paper-o:before {
  content: "\f256";
}
.fa-hand-scissors-o:before {
  content: "\f257";
}
.fa-hand-lizard-o:before {
  content: "\f258";
}
.fa-hand-spock-o:before {
  content: "\f259";
}
.fa-hand-pointer-o:before {
  content: "\f25a";
}
.fa-hand-peace-o:before {
  content: "\f25b";
}
.fa-trademark:before {
  content: "\f25c";
}
.fa-registered:before {
  content: "\f25d";
}
.fa-creative-commons:before {
  content: "\f25e";
}
.fa-gg:before {
  content: "\f260";
}
.fa-gg-circle:before {
  content: "\f261";
}
.fa-tripadvisor:before {
  content: "\f262";
}
.fa-odnoklassniki:before {
  content: "\f263";
}
.fa-odnoklassniki-square:before {
  content: "\f264";
}
.fa-get-pocket:before {
  content: "\f265";
}
.fa-wikipedia-w:before {
  content: "\f266";
}
.fa-safari:before {
  content: "\f267";
}
.fa-chrome:before {
  content: "\f268";
}
.fa-firefox:before {
  content: "\f269";
}
.fa-opera:before {
  content: "\f26a";
}
.fa-internet-explorer:before {
  content: "\f26b";
}
.fa-tv:before,
.fa-television:before {
  content: "\f26c";
}
.fa-contao:before {
  content: "\f26d";
}
.fa-500px:before {
  content: "\f26e";
}
.fa-amazon:before {
  content: "\f270";
}
.fa-calendar-plus-o:before {
  content: "\f271";
}
.fa-calendar-minus-o:before {
  content: "\f272";
}
.fa-calendar-times-o:before {
  content: "\f273";
}
.fa-calendar-check-o:before {
  content: "\f274";
}
.fa-industry:before {
  content: "\f275";
}
.fa-map-pin:before {
  content: "\f276";
}
.fa-map-signs:before {
  content: "\f277";
}
.fa-map-o:before {
  content: "\f278";
}
.fa-map:before {
  content: "\f279";
}
.fa-commenting:before {
  content: "\f27a";
}
.fa-commenting-o:before {
  content: "\f27b";
}
.fa-houzz:before {
  content: "\f27c";
}
.fa-vimeo:before {
  content: "\f27d";
}
.fa-black-tie:before {
  content: "\f27e";
}
.fa-fonticons:before {
  content: "\f280";
}


/* Linea icon pack */

.l-banknote:before {
  content: "a";
}
.l-weather-aquarius:before {
  content: "b";
}
.l-weather-aries:before {
  content: "c";
}
.l-weather-cancer:before {
  content: "d";
}
.l-weather-capricorn:before {
  content: "e";
}
.l-weather-cloud:before {
  content: "f";
}
.l-weather-cloud-drop:before {
  content: "g";
}
.l-weather-cloud-lightning:before {
  content: "h";
}
.l-weather-cloud-snowflake:before {
  content: "i";
}
.l-weather-downpour-fullmoon:before {
  content: "j";
}
.l-weather-downpour-halfmoon:before {
  content: "k";
}
.l-weather-downpour-sun:before {
  content: "l";
}
.l-weather-drop:before {
  content: "m";
}
.l-weather-first-quarter:before {
  content: "n";
}
.l-weather-fog:before {
  content: "o";
}
.l-weather-fog-fullmoon:before {
  content: "p";
}
.l-weather-fog-halfmoon:before {
  content: "q";
}
.l-weather-fog-sun:before {
  content: "r";
}
.l-weather-fullmoon:before {
  content: "s";
}
.l-weather-gemini:before {
  content: "t";
}
.l-weather-hail:before {
  content: "u";
}
.l-weather-hail-fullmoon:before {
  content: "v";
}
.l-weather-hail-halfmoon:before {
  content: "w";
}
.l-weather-hail-sun:before {
  content: "x";
}
.l-weather-last-quarter:before {
  content: "y";
}
.l-weather-leo:before {
  content: "z";
}
.l-weather-libra:before {
  content: "A";
}
.l-weather-lightning:before {
  content: "B";
}
.l-weather-mistyrain:before {
  content: "C";
}
.l-weather-mistyrain-fullmoon:before {
  content: "D";
}
.l-weather-mistyrain-halfmoon:before {
  content: "E";
}
.l-weather-mistyrain-sun:before {
  content: "F";
}
.l-weather-moon:before {
  content: "G";
}
.l-weather-moondown-full:before {
  content: "H";
}
.l-weather-moondown-half:before {
  content: "I";
}
.l-weather-moonset-full:before {
  content: "J";
}
.l-weather-moonset-half:before {
  content: "K";
}
.l-weather-move2:before {
  content: "L";
}
.l-weather-newmoon:before {
  content: "M";
}
.l-weather-pisces:before {
  content: "N";
}
.l-weather-rain:before {
  content: "O";
}
.l-weather-rain-fullmoon:before {
  content: "P";
}
.l-weather-rain-halfmoon:before {
  content: "Q";
}
.l-weather-rain-sun:before {
  content: "R";
}
.l-weather-sagittarius:before {
  content: "S";
}
.l-weather-scorpio:before {
  content: "T";
}
.l-weather-snow:before {
  content: "U";
}
.l-weather-snow-fullmoon:before {
  content: "V";
}
.l-weather-snow-halfmoon:before {
  content: "W";
}
.l-weather-snow-sun:before {
  content: "X";
}
.l-weather-snowflake:before {
  content: "Y";
}
.l-weather-star:before {
  content: "Z";
}
.l-weather-storm-11:before {
  content: "0";
}
.l-weather-storm-32:before {
  content: "1";
}
.l-weather-storm-fullmoon:before {
  content: "2";
}
.l-weather-storm-halfmoon:before {
  content: "3";
}
.l-weather-storm-sun:before {
  content: "4";
}
.l-weather-sun:before {
  content: "5";
}
.l-weather-sundown:before {
  content: "6";
}
.l-weather-sunset:before {
  content: "7";
}
.l-weather-taurus:before {
  content: "8";
}
.l-weather-tempest:before {
  content: "9";
}
.l-weather-tempest-fullmoon:before {
  content: "!";
}
.l-weather-tempest-halfmoon:before {
  content: "\"";
}
.l-weather-tempest-sun:before {
  content: "#";
}
.l-weather-variable-fullmoon:before {
  content: "$";
}
.l-weather-variable-halfmoon:before {
  content: "%";
}
.l-weather-variable-sun:before {
  content: "&";
}
.l-weather-virgo:before {
  content: "'";
}
.l-weather-waning-cresent:before {
  content: "(";
}
.l-weather-waning-gibbous:before {
  content: ")";
}
.l-weather-waxing-cresent:before {
  content: "*";
}
.l-weather-waxing-gibbous:before {
  content: "+";
}
.l-weather-wind:before {
  content: ",";
}
.l-weather-wind-e:before {
  content: "-";
}
.l-weather-wind-fullmoon:before {
  content: ".";
}
.l-weather-wind-halfmoon:before {
  content: "/";
}
.l-weather-wind-n:before {
  content: ":";
}
.l-weather-wind-ne:before {
  content: ";";
}
.l-weather-wind-nw:before {
  content: "<";
}
.l-weather-wind-s:before {
  content: "=";
}
.l-weather-wind-se:before {
  content: ">";
}
.l-weather-wind-sun:before {
  content: "?";
}
.l-weather-wind-sw:before {
  content: "@";
}
.l-weather-wind-w:before {
  content: "[";
}
.l-weather-windgust:before {
  content: "]";
}
.l-software-add-vectorpoint:before {
  content: "^";
}
.l-software-box-oval:before {
  content: "_";
}
.l-software-box-polygon:before {
  content: "`";
}
.l-software-box-rectangle:before {
  content: "{";
}
.l-software-box-roundedrectangle:before {
  content: "|";
}
.l-software-character:before {
  content: "}";
}
.l-software-crop:before {
  content: "~";
}
.l-software-eyedropper:before {
  content: "\\";
}
.l-software-font-allcaps:before {
  content: "\e000";
}
.l-software-font-baseline-shift:before {
  content: "\e001";
}
.l-software-font-horizontal-scale:before {
  content: "\e002";
}
.l-software-font-kerning:before {
  content: "\e003";
}
.l-software-font-leading:before {
  content: "\e004";
}
.l-software-font-size:before {
  content: "\e005";
}
.l-software-font-smallcapital:before {
  content: "\e006";
}
.l-software-font-smallcaps:before {
  content: "\e007";
}
.l-software-font-strikethrough:before {
  content: "\e008";
}
.l-software-font-tracking:before {
  content: "\e009";
}
.l-software-font-underline:before {
  content: "\e00a";
}
.l-software-font-vertical-scale:before {
  content: "\e00b";
}
.l-software-horizontal-align-center:before {
  content: "\e00c";
}
.l-software-horizontal-align-left:before {
  content: "\e00d";
}
.l-software-horizontal-align-right:before {
  content: "\e00e";
}
.l-software-horizontal-distribute-center:before {
  content: "\e00f";
}
.l-software-horizontal-distribute-left:before {
  content: "\e010";
}
.l-software-horizontal-distribute-right:before {
  content: "\e011";
}
.l-software-indent-firstline:before {
  content: "\e012";
}
.l-software-indent-left:before {
  content: "\e013";
}
.l-software-indent-right:before {
  content: "\e014";
}
.l-software-lasso:before {
  content: "\e015";
}
.l-software-layers1:before {
  content: "\e016";
}
.l-software-layers2:before {
  content: "\e017";
}
.l-software-layout:before {
  content: "\e018";
}
.l-software-layout-2columns:before {
  content: "\e019";
}
.l-software-layout-3columns:before {
  content: "\e01a";
}
.l-software-layout-4boxes:before {
  content: "\e01b";
}
.l-software-layout-4columns:before {
  content: "\e01c";
}
.l-software-layout-4lines:before {
  content: "\e01d";
}
.l-software-layout-8boxes:before {
  content: "\e01e";
}
.l-software-layout-header:before {
  content: "\e01f";
}
.l-software-layout-header-2columns:before {
  content: "\e020";
}
.l-software-layout-header-3columns:before {
  content: "\e021";
}
.l-software-layout-header-4boxes:before {
  content: "\e022";
}
.l-software-layout-header-4columns:before {
  content: "\e023";
}
.l-software-layout-header-complex:before {
  content: "\e024";
}
.l-software-layout-header-complex2:before {
  content: "\e025";
}
.l-software-layout-header-complex3:before {
  content: "\e026";
}
.l-software-layout-header-complex4:before {
  content: "\e027";
}
.l-software-layout-header-sideleft:before {
  content: "\e028";
}
.l-software-layout-header-sideright:before {
  content: "\e029";
}
.l-software-layout-sidebar-left:before {
  content: "\e02a";
}
.l-software-layout-sidebar-right:before {
  content: "\e02b";
}
.l-software-magnete:before {
  content: "\e02c";
}
.l-software-pages:before {
  content: "\e02d";
}
.l-software-paintbrush:before {
  content: "\e02e";
}
.l-software-paintbucket:before {
  content: "\e02f";
}
.l-software-paintroller:before {
  content: "\e030";
}
.l-software-paragraph:before {
  content: "\e031";
}
.l-software-paragraph-align-left:before {
  content: "\e032";
}
.l-software-paragraph-align-right:before {
  content: "\e033";
}
.l-software-paragraph-center:before {
  content: "\e034";
}
.l-software-paragraph-justify-all:before {
  content: "\e035";
}
.l-software-paragraph-justify-center:before {
  content: "\e036";
}
.l-software-paragraph-justify-left:before {
  content: "\e037";
}
.l-software-paragraph-justify-right:before {
  content: "\e038";
}
.l-software-paragraph-space-after:before {
  content: "\e039";
}
.l-software-paragraph-space-before:before {
  content: "\e03a";
}
.l-software-pathfinder-exclude:before {
  content: "\e03b";
}
.l-software-pathfinder-intersect:before {
  content: "\e03c";
}
.l-software-pathfinder-subtract:before {
  content: "\e03d";
}
.l-software-pathfinder-unite:before {
  content: "\e03e";
}
.l-software-pen:before {
  content: "\e03f";
}
.l-software-pen-add:before {
  content: "\e040";
}
.l-software-pen-remove:before {
  content: "\e041";
}
.l-software-pencil:before {
  content: "\e042";
}
.l-software-polygonallasso:before {
  content: "\e043";
}
.l-software-reflect-horizontal:before {
  content: "\e044";
}
.l-software-reflect-vertical:before {
  content: "\e045";
}
.l-software-remove-vectorpoint:before {
  content: "\e046";
}
.l-software-scale-expand:before {
  content: "\e047";
}
.l-software-scale-reduce:before {
  content: "\e048";
}
.l-software-selection-oval:before {
  content: "\e049";
}
.l-software-selection-polygon:before {
  content: "\e04a";
}
.l-software-selection-rectangle:before {
  content: "\e04b";
}
.l-software-selection-roundedrectangle:before {
  content: "\e04c";
}
.l-software-shape-oval:before {
  content: "\e04d";
}
.l-software-shape-polygon:before {
  content: "\e04e";
}
.l-software-shape-rectangle:before {
  content: "\e04f";
}
.l-software-shape-roundedrectangle:before {
  content: "\e050";
}
.l-software-slice:before {
  content: "\e051";
}
.l-software-transform-bezier:before {
  content: "\e052";
}
.l-software-vector-box:before {
  content: "\e053";
}
.l-software-vector-composite:before {
  content: "\e054";
}
.l-software-vector-line:before {
  content: "\e055";
}
.l-software-vertical-align-bottom:before {
  content: "\e056";
}
.l-software-vertical-align-center:before {
  content: "\e057";
}
.l-software-vertical-align-top:before {
  content: "\e058";
}
.l-software-vertical-distribute-bottom:before {
  content: "\e059";
}
.l-software-vertical-distribute-center:before {
  content: "\e05a";
}
.l-software-vertical-distribute-top:before {
  content: "\e05b";
}
.l-music-beginning-button:before {
  content: "\e05c";
}
.l-music-bell:before {
  content: "\e05d";
}
.l-music-cd:before {
  content: "\e05e";
}
.l-music-diapason:before {
  content: "\e05f";
}
.l-music-eject-button:before {
  content: "\e060";
}
.l-music-end-button:before {
  content: "\e061";
}
.l-music-fastforward-button:before {
  content: "\e062";
}
.l-music-headphones:before {
  content: "\e063";
}
.l-music-ipod:before {
  content: "\e064";
}
.l-music-loudspeaker:before {
  content: "\e065";
}
.l-music-microphone:before {
  content: "\e066";
}
.l-music-microphone-old:before {
  content: "\e067";
}
.l-music-mixer:before {
  content: "\e068";
}
.l-music-mute:before {
  content: "\e069";
}
.l-music-note-multiple:before {
  content: "\e06a";
}
.l-music-note-single:before {
  content: "\e06b";
}
.l-music-pause-button:before {
  content: "\e06c";
}
.l-music-play-button:before {
  content: "\e06d";
}
.l-music-playlist:before {
  content: "\e06e";
}
.l-music-radio-ghettoblaster:before {
  content: "\e06f";
}
.l-music-radio-portable:before {
  content: "\e070";
}
.l-music-record:before {
  content: "\e071";
}
.l-music-recordplayer:before {
  content: "\e072";
}
.l-music-repeat-button:before {
  content: "\e073";
}
.l-music-rewind-button:before {
  content: "\e074";
}
.l-music-shuffle-button:before {
  content: "\e075";
}
.l-music-stop-button:before {
  content: "\e076";
}
.l-music-tape:before {
  content: "\e077";
}
.l-music-volume-down:before {
  content: "\e078";
}
.l-music-volume-up:before {
  content: "\e079";
}
.l-ecommerce-bag:before {
  content: "\e07a";
}
.l-ecommerce-bag-check:before {
  content: "\e07b";
}
.l-ecommerce-bag-cloud:before {
  content: "\e07c";
}
.l-ecommerce-bag-download:before {
  content: "\e07d";
}
.l-ecommerce-bag-minus:before {
  content: "\e07e";
}
.l-ecommerce-bag-plus:before {
  content: "\e07f";
}
.l-ecommerce-bag-refresh:before {
  content: "\e080";
}
.l-ecommerce-bag-remove:before {
  content: "\e081";
}
.l-ecommerce-bag-search:before {
  content: "\e082";
}
.l-ecommerce-bag-upload:before {
  content: "\e083";
}
.l-ecommerce-banknote:before {
  content: "\e084";
}
.l-ecommerce-banknotes:before {
  content: "\e085";
}
.l-ecommerce-basket:before {
  content: "\e086";
}
.l-ecommerce-basket-check:before {
  content: "\e087";
}
.l-ecommerce-basket-cloud:before {
  content: "\e088";
}
.l-ecommerce-basket-download:before {
  content: "\e089";
}
.l-ecommerce-basket-minus:before {
  content: "\e08a";
}
.l-ecommerce-basket-plus:before {
  content: "\e08b";
}
.l-ecommerce-basket-refresh:before {
  content: "\e08c";
}
.l-ecommerce-basket-remove:before {
  content: "\e08d";
}
.l-ecommerce-basket-search:before {
  content: "\e08e";
}
.l-ecommerce-basket-upload:before {
  content: "\e08f";
}
.l-ecommerce-bath:before {
  content: "\e090";
}
.l-ecommerce-cart:before {
  content: "\e091";
}
.l-ecommerce-cart-check:before {
  content: "\e092";
}
.l-ecommerce-cart-cloud:before {
  content: "\e093";
}
.l-ecommerce-cart-content:before {
  content: "\e094";
}
.l-ecommerce-cart-download:before {
  content: "\e095";
}
.l-ecommerce-cart-minus:before {
  content: "\e096";
}
.l-ecommerce-cart-plus:before {
  content: "\e097";
}
.l-ecommerce-cart-refresh:before {
  content: "\e098";
}
.l-ecommerce-cart-remove:before {
  content: "\e099";
}
.l-ecommerce-cart-search:before {
  content: "\e09a";
}
.l-ecommerce-cart-upload:before {
  content: "\e09b";
}
.l-ecommerce-cent:before {
  content: "\e09c";
}
.l-ecommerce-colon:before {
  content: "\e09d";
}
.l-ecommerce-creditcard:before {
  content: "\e09e";
}
.l-ecommerce-diamond:before {
  content: "\e09f";
}
.l-ecommerce-dollar:before {
  content: "\e0a0";
}
.l-ecommerce-euro:before {
  content: "\e0a1";
}
.l-ecommerce-franc:before {
  content: "\e0a2";
}
.l-ecommerce-gift:before {
  content: "\e0a3";
}
.l-ecommerce-graph1:before {
  content: "\e0a4";
}
.l-ecommerce-graph2:before {
  content: "\e0a5";
}
.l-ecommerce-graph3:before {
  content: "\e0a6";
}
.l-ecommerce-graph-decrease:before {
  content: "\e0a7";
}
.l-ecommerce-graph-increase:before {
  content: "\e0a8";
}
.l-ecommerce-guarani:before {
  content: "\e0a9";
}
.l-ecommerce-kips:before {
  content: "\e0aa";
}
.l-ecommerce-lira:before {
  content: "\e0ab";
}
.l-ecommerce-megaphone:before {
  content: "\e0ac";
}
.l-ecommerce-money:before {
  content: "\e0ad";
}
.l-ecommerce-naira:before {
  content: "\e0ae";
}
.l-ecommerce-pesos:before {
  content: "\e0af";
}
.l-ecommerce-pound:before {
  content: "\e0b0";
}
.l-ecommerce-receipt:before {
  content: "\e0b1";
}
.l-ecommerce-receipt-bath:before {
  content: "\e0b2";
}
.l-ecommerce-receipt-cent:before {
  content: "\e0b3";
}
.l-ecommerce-receipt-dollar:before {
  content: "\e0b4";
}
.l-ecommerce-receipt-euro:before {
  content: "\e0b5";
}
.l-ecommerce-receipt-franc:before {
  content: "\e0b6";
}
.l-ecommerce-receipt-guarani:before {
  content: "\e0b7";
}
.l-ecommerce-receipt-kips:before {
  content: "\e0b8";
}
.l-ecommerce-receipt-lira:before {
  content: "\e0b9";
}
.l-ecommerce-receipt-naira:before {
  content: "\e0ba";
}
.l-ecommerce-receipt-pesos:before {
  content: "\e0bb";
}
.l-ecommerce-receipt-pound:before {
  content: "\e0bc";
}
.l-ecommerce-receipt-rublo:before {
  content: "\e0bd";
}
.l-ecommerce-receipt-rupee:before {
  content: "\e0be";
}
.l-ecommerce-receipt-tugrik:before {
  content: "\e0bf";
}
.l-ecommerce-receipt-won:before {
  content: "\e0c0";
}
.l-ecommerce-receipt-yen:before {
  content: "\e0c1";
}
.l-ecommerce-receipt-yen2:before {
  content: "\e0c2";
}
.l-ecommerce-recept-colon:before {
  content: "\e0c3";
}
.l-ecommerce-rublo:before {
  content: "\e0c4";
}
.l-ecommerce-rupee:before {
  content: "\e0c5";
}
.l-ecommerce-safe:before {
  content: "\e0c6";
}
.l-ecommerce-sale:before {
  content: "\e0c7";
}
.l-ecommerce-sales:before {
  content: "\e0c8";
}
.l-ecommerce-ticket:before {
  content: "\e0c9";
}
.l-ecommerce-tugriks:before {
  content: "\e0ca";
}
.l-ecommerce-wallet:before {
  content: "\e0cb";
}
.l-ecommerce-won:before {
  content: "\e0cc";
}
.l-ecommerce-yen:before {
  content: "\e0cd";
}
.l-ecommerce-yen2:before {
  content: "\e0ce";
}
.l-basic-elaboration-bookmark-checck:before {
  content: "\e0cf";
}
.l-basic-elaboration-bookmark-minus:before {
  content: "\e0d0";
}
.l-basic-elaboration-bookmark-plus:before {
  content: "\e0d1";
}
.l-basic-elaboration-bookmark-remove:before {
  content: "\e0d2";
}
.l-basic-elaboration-briefcase-check:before {
  content: "\e0d3";
}
.l-basic-elaboration-briefcase-download:before {
  content: "\e0d4";
}
.l-basic-elaboration-briefcase-flagged:before {
  content: "\e0d5";
}
.l-basic-elaboration-briefcase-minus:before {
  content: "\e0d6";
}
.l-basic-elaboration-briefcase-plus:before {
  content: "\e0d7";
}
.l-basic-elaboration-briefcase-refresh:before {
  content: "\e0d8";
}
.l-basic-elaboration-briefcase-remove:before {
  content: "\e0d9";
}
.l-basic-elaboration-briefcase-search:before {
  content: "\e0da";
}
.l-basic-elaboration-briefcase-star:before {
  content: "\e0db";
}
.l-basic-elaboration-briefcase-upload:before {
  content: "\e0dc";
}
.l-basic-elaboration-browser-check:before {
  content: "\e0dd";
}
.l-basic-elaboration-browser-download:before {
  content: "\e0de";
}
.l-basic-elaboration-browser-minus:before {
  content: "\e0df";
}
.l-basic-elaboration-browser-plus:before {
  content: "\e0e0";
}
.l-basic-elaboration-browser-refresh:before {
  content: "\e0e1";
}
.l-basic-elaboration-browser-remove:before {
  content: "\e0e2";
}
.l-basic-elaboration-browser-search:before {
  content: "\e0e3";
}
.l-basic-elaboration-browser-star:before {
  content: "\e0e4";
}
.l-basic-elaboration-browser-upload:before {
  content: "\e0e5";
}
.l-basic-elaboration-calendar-check:before {
  content: "\e0e6";
}
.l-basic-elaboration-calendar-cloud:before {
  content: "\e0e7";
}
.l-basic-elaboration-calendar-download:before {
  content: "\e0e8";
}
.l-basic-elaboration-calendar-empty:before {
  content: "\e0e9";
}
.l-basic-elaboration-calendar-flagged:before {
  content: "\e0ea";
}
.l-basic-elaboration-calendar-heart:before {
  content: "\e0eb";
}
.l-basic-elaboration-calendar-minus:before {
  content: "\e0ec";
}
.l-basic-elaboration-calendar-next:before {
  content: "\e0ed";
}
.l-basic-elaboration-calendar-noaccess:before {
  content: "\e0ee";
}
.l-basic-elaboration-calendar-pencil:before {
  content: "\e0ef";
}
.l-basic-elaboration-calendar-plus:before {
  content: "\e0f0";
}
.l-basic-elaboration-calendar-previous:before {
  content: "\e0f1";
}
.l-basic-elaboration-calendar-refresh:before {
  content: "\e0f2";
}
.l-basic-elaboration-calendar-remove:before {
  content: "\e0f3";
}
.l-basic-elaboration-calendar-search:before {
  content: "\e0f4";
}
.l-basic-elaboration-calendar-star:before {
  content: "\e0f5";
}
.l-basic-elaboration-calendar-upload:before {
  content: "\e0f6";
}
.l-basic-elaboration-cloud-check:before {
  content: "\e0f7";
}
.l-basic-elaboration-cloud-download:before {
  content: "\e0f8";
}
.l-basic-elaboration-cloud-minus:before {
  content: "\e0f9";
}
.l-basic-elaboration-cloud-noaccess:before {
  content: "\e0fa";
}
.l-basic-elaboration-cloud-plus:before {
  content: "\e0fb";
}
.l-basic-elaboration-cloud-refresh:before {
  content: "\e0fc";
}
.l-basic-elaboration-cloud-remove:before {
  content: "\e0fd";
}
.l-basic-elaboration-cloud-search:before {
  content: "\e0fe";
}
.l-basic-elaboration-cloud-upload:before {
  content: "\e0ff";
}
.l-basic-elaboration-document-check:before {
  content: "\e100";
}
.l-basic-elaboration-document-cloud:before {
  content: "\e101";
}
.l-basic-elaboration-document-download:before {
  content: "\e102";
}
.l-basic-elaboration-document-flagged:before {
  content: "\e103";
}
.l-basic-elaboration-document-graph:before {
  content: "\e104";
}
.l-basic-elaboration-document-heart:before {
  content: "\e105";
}
.l-basic-elaboration-document-minus:before {
  content: "\e106";
}
.l-basic-elaboration-document-next:before {
  content: "\e107";
}
.l-basic-elaboration-document-noaccess:before {
  content: "\e108";
}
.l-basic-elaboration-document-note:before {
  content: "\e109";
}
.l-basic-elaboration-document-pencil:before {
  content: "\e10a";
}
.l-basic-elaboration-document-picture:before {
  content: "\e10b";
}
.l-basic-elaboration-document-plus:before {
  content: "\e10c";
}
.l-basic-elaboration-document-previous:before {
  content: "\e10d";
}
.l-basic-elaboration-document-refresh:before {
  content: "\e10e";
}
.l-basic-elaboration-document-remove:before {
  content: "\e10f";
}
.l-basic-elaboration-document-search:before {
  content: "\e110";
}
.l-basic-elaboration-document-star:before {
  content: "\e111";
}
.l-basic-elaboration-document-upload:before {
  content: "\e112";
}
.l-basic-elaboration-folder-check:before {
  content: "\e113";
}
.l-basic-elaboration-folder-cloud:before {
  content: "\e114";
}
.l-basic-elaboration-folder-document:before {
  content: "\e115";
}
.l-basic-elaboration-folder-download:before {
  content: "\e116";
}
.l-basic-elaboration-folder-flagged:before {
  content: "\e117";
}
.l-basic-elaboration-folder-graph:before {
  content: "\e118";
}
.l-basic-elaboration-folder-heart:before {
  content: "\e119";
}
.l-basic-elaboration-folder-minus:before {
  content: "\e11a";
}
.l-basic-elaboration-folder-next:before {
  content: "\e11b";
}
.l-basic-elaboration-folder-noaccess:before {
  content: "\e11c";
}
.l-basic-elaboration-folder-note:before {
  content: "\e11d";
}
.l-basic-elaboration-folder-pencil:before {
  content: "\e11e";
}
.l-basic-elaboration-folder-picture:before {
  content: "\e11f";
}
.l-basic-elaboration-folder-plus:before {
  content: "\e120";
}
.l-basic-elaboration-folder-previous:before {
  content: "\e121";
}
.l-basic-elaboration-folder-refresh:before {
  content: "\e122";
}
.l-basic-elaboration-folder-remove:before {
  content: "\e123";
}
.l-basic-elaboration-folder-search:before {
  content: "\e124";
}
.l-basic-elaboration-folder-star:before {
  content: "\e125";
}
.l-basic-elaboration-folder-upload:before {
  content: "\e126";
}
.l-basic-elaboration-mail-check:before {
  content: "\e127";
}
.l-basic-elaboration-mail-cloud:before {
  content: "\e128";
}
.l-basic-elaboration-mail-document:before {
  content: "\e129";
}
.l-basic-elaboration-mail-download:before {
  content: "\e12a";
}
.l-basic-elaboration-mail-flagged:before {
  content: "\e12b";
}
.l-basic-elaboration-mail-heart:before {
  content: "\e12c";
}
.l-basic-elaboration-mail-next:before {
  content: "\e12d";
}
.l-basic-elaboration-mail-noaccess:before {
  content: "\e12e";
}
.l-basic-elaboration-mail-note:before {
  content: "\e12f";
}
.l-basic-elaboration-mail-pencil:before {
  content: "\e130";
}
.l-basic-elaboration-mail-picture:before {
  content: "\e131";
}
.l-basic-elaboration-mail-previous:before {
  content: "\e132";
}
.l-basic-elaboration-mail-refresh:before {
  content: "\e133";
}
.l-basic-elaboration-mail-remove:before {
  content: "\e134";
}
.l-basic-elaboration-mail-search:before {
  content: "\e135";
}
.l-basic-elaboration-mail-star:before {
  content: "\e136";
}
.l-basic-elaboration-mail-upload:before {
  content: "\e137";
}
.l-basic-elaboration-message-check:before {
  content: "\e138";
}
.l-basic-elaboration-message-dots:before {
  content: "\e139";
}
.l-basic-elaboration-message-happy:before {
  content: "\e13a";
}
.l-basic-elaboration-message-heart:before {
  content: "\e13b";
}
.l-basic-elaboration-message-minus:before {
  content: "\e13c";
}
.l-basic-elaboration-message-note:before {
  content: "\e13d";
}
.l-basic-elaboration-message-plus:before {
  content: "\e13e";
}
.l-basic-elaboration-message-refresh:before {
  content: "\e13f";
}
.l-basic-elaboration-message-remove:before {
  content: "\e140";
}
.l-basic-elaboration-message-sad:before {
  content: "\e141";
}
.l-basic-elaboration-smartphone-cloud:before {
  content: "\e142";
}
.l-basic-elaboration-smartphone-heart:before {
  content: "\e143";
}
.l-basic-elaboration-smartphone-noaccess:before {
  content: "\e144";
}
.l-basic-elaboration-smartphone-note:before {
  content: "\e145";
}
.l-basic-elaboration-smartphone-pencil:before {
  content: "\e146";
}
.l-basic-elaboration-smartphone-picture:before {
  content: "\e147";
}
.l-basic-elaboration-smartphone-refresh:before {
  content: "\e148";
}
.l-basic-elaboration-smartphone-search:before {
  content: "\e149";
}
.l-basic-elaboration-tablet-cloud:before {
  content: "\e14a";
}
.l-basic-elaboration-tablet-heart:before {
  content: "\e14b";
}
.l-basic-elaboration-tablet-noaccess:before {
  content: "\e14c";
}
.l-basic-elaboration-tablet-note:before {
  content: "\e14d";
}
.l-basic-elaboration-tablet-pencil:before {
  content: "\e14e";
}
.l-basic-elaboration-tablet-picture:before {
  content: "\e14f";
}
.l-basic-elaboration-tablet-refresh:before {
  content: "\e150";
}
.l-basic-elaboration-tablet-search:before {
  content: "\e151";
}
.l-basic-elaboration-todolist-2:before {
  content: "\e152";
}
.l-basic-elaboration-todolist-check:before {
  content: "\e153";
}
.l-basic-elaboration-todolist-cloud:before {
  content: "\e154";
}
.l-basic-elaboration-todolist-download:before {
  content: "\e155";
}
.l-basic-elaboration-todolist-flagged:before {
  content: "\e156";
}
.l-basic-elaboration-todolist-minus:before {
  content: "\e157";
}
.l-basic-elaboration-todolist-noaccess:before {
  content: "\e158";
}
.l-basic-elaboration-todolist-pencil:before {
  content: "\e159";
}
.l-basic-elaboration-todolist-plus:before {
  content: "\e15a";
}
.l-basic-elaboration-todolist-refresh:before {
  content: "\e15b";
}
.l-basic-elaboration-todolist-remove:before {
  content: "\e15c";
}
.l-basic-elaboration-todolist-search:before {
  content: "\e15d";
}
.l-basic-elaboration-todolist-star:before {
  content: "\e15e";
}
.l-basic-elaboration-todolist-upload:before {
  content: "\e15f";
}
.l-basic-accelerator:before {
  content: "\e160";
}
.l-basic-alarm:before {
  content: "\e161";
}
.l-basic-anchor:before {
  content: "\e162";
}
.l-basic-anticlockwise:before {
  content: "\e163";
}
.l-basic-archive:before {
  content: "\e164";
}
.l-basic-archive-full:before {
  content: "\e165";
}
.l-basic-ban:before {
  content: "\e166";
}
.l-basic-battery-charge:before {
  content: "\e167";
}
.l-basic-battery-empty:before {
  content: "\e168";
}
.l-basic-battery-full:before {
  content: "\e169";
}
.l-basic-battery-half:before {
  content: "\e16a";
}
.l-basic-bolt:before {
  content: "\e16b";
}
.l-basic-book:before {
  content: "\e16c";
}
.l-basic-book-pen:before {
  content: "\e16d";
}
.l-basic-book-pencil:before {
  content: "\e16e";
}
.l-basic-bookmark:before {
  content: "\e16f";
}
.l-basic-calculator:before {
  content: "\e170";
}
.l-basic-calendar:before {
  content: "\e171";
}
.l-basic-cards-diamonds:before {
  content: "\e172";
}
.l-basic-cards-hearts:before {
  content: "\e173";
}
.l-basic-case:before {
  content: "\e174";
}
.l-basic-chronometer:before {
  content: "\e175";
}
.l-basic-clessidre:before {
  content: "\e176";
}
.l-basic-clock:before {
  content: "\e177";
}
.l-basic-clockwise:before {
  content: "\e178";
}
.l-basic-cloud:before {
  content: "\e179";
}
.l-basic-clubs:before {
  content: "\e17a";
}
.l-basic-compass:before {
  content: "\e17b";
}
.l-basic-cup:before {
  content: "\e17c";
}
.l-basic-diamonds:before {
  content: "\e17d";
}
.l-basic-display:before {
  content: "\e17e";
}
.l-basic-download:before {
  content: "\e17f";
}
.l-basic-exclamation:before {
  content: "\e180";
}
.l-basic-eye:before {
  content: "\e181";
}
.l-basic-eye-closed:before {
  content: "\e182";
}
.l-basic-female:before {
  content: "\e183";
}
.l-basic-flag1:before {
  content: "\e184";
}
.l-basic-flag2:before {
  content: "\e185";
}
.l-basic-floppydisk:before {
  content: "\e186";
}
.l-basic-folder:before {
  content: "\e187";
}
.l-basic-folder-multiple:before {
  content: "\e188";
}
.l-basic-gear:before {
  content: "\e189";
}
.l-basic-geolocalize-01:before {
  content: "\e18a";
}
.l-basic-geolocalize-05:before {
  content: "\e18b";
}
.l-basic-globe:before {
  content: "\e18c";
}
.l-basic-gunsight:before {
  content: "\e18d";
}
.l-basic-hammer:before {
  content: "\e18e";
}
.l-basic-headset:before {
  content: "\e18f";
}
.l-basic-heart:before {
  content: "\e190";
}
.l-basic-heart-broken:before {
  content: "\e191";
}
.l-basic-helm:before {
  content: "\e192";
}
.l-basic-home:before {
  content: "\e193";
}
.l-basic-info:before {
  content: "\e194";
}
.l-basic-ipod:before {
  content: "\e195";
}
.l-basic-joypad:before {
  content: "\e196";
}
.l-basic-key:before {
  content: "\e197";
}
.l-basic-keyboard:before {
  content: "\e198";
}
.l-basic-laptop:before {
  content: "\e199";
}
.l-basic-life-buoy:before {
  content: "\e19a";
}
.l-basic-lightbulb:before {
  content: "\e19b";
}
.l-basic-link:before {
  content: "\e19c";
}
.l-basic-lock:before {
  content: "\e19d";
}
.l-basic-lock-open:before {
  content: "\e19e";
}
.l-basic-magic-mouse:before {
  content: "\e19f";
}
.l-basic-magnifier:before {
  content: "\e1a0";
}
.l-basic-magnifier-minus:before {
  content: "\e1a1";
}
.l-basic-magnifier-plus:before {
  content: "\e1a2";
}
.l-basic-mail:before {
  content: "\e1a3";
}
.l-basic-mail-multiple:before {
  content: "\e1a4";
}
.l-basic-mail-open:before {
  content: "\e1a5";
}
.l-basic-mail-open-text:before {
  content: "\e1a6";
}
.l-basic-male:before {
  content: "\e1a7";
}
.l-basic-map:before {
  content: "\e1a8";
}
.l-basic-message:before {
  content: "\e1a9";
}
.l-basic-message-multiple:before {
  content: "\e1aa";
}
.l-basic-message-txt:before {
  content: "\e1ab";
}
.l-basic-mixer2:before {
  content: "\e1ac";
}
.l-basic-mouse:before {
  content: "\e1ad";
}
.l-basic-notebook:before {
  content: "\e1ae";
}
.l-basic-notebook-pen:before {
  content: "\e1af";
}
.l-basic-notebook-pencil:before {
  content: "\e1b0";
}
.l-basic-paperplane:before {
  content: "\e1b1";
}
.l-basic-pencil-ruler:before {
  content: "\e1b2";
}
.l-basic-pencil-ruler-pen:before {
  content: "\e1b3";
}
.l-basic-photo:before {
  content: "\e1b4";
}
.l-basic-picture:before {
  content: "\e1b5";
}
.l-basic-picture-multiple:before {
  content: "\e1b6";
}
.l-basic-pin1:before {
  content: "\e1b7";
}
.l-basic-pin2:before {
  content: "\e1b8";
}
.l-basic-postcard:before {
  content: "\e1b9";
}
.l-basic-postcard-multiple:before {
  content: "\e1ba";
}
.l-basic-printer:before {
  content: "\e1bb";
}
.l-basic-question:before {
  content: "\e1bc";
}
.l-basic-rss:before {
  content: "\e1bd";
}
.l-basic-server:before {
  content: "\e1be";
}
.l-basic-server2:before {
  content: "\e1bf";
}
.l-basic-server-cloud:before {
  content: "\e1c0";
}
.l-basic-server-download:before {
  content: "\e1c1";
}
.l-basic-server-upload:before {
  content: "\e1c2";
}
.l-basic-settings:before {
  content: "\e1c3";
}
.l-basic-share:before {
  content: "\e1c4";
}
.l-basic-sheet:before {
  content: "\e1c5";
}
.l-basic-sheet-multiple:before {
  content: "\e1c6";
}
.l-basic-sheet-pen:before {
  content: "\e1c7";
}
.l-basic-sheet-pencil:before {
  content: "\e1c8";
}
.l-basic-sheet-txt:before {
  content: "\e1c9";
}
.l-basic-signs:before {
  content: "\e1ca";
}
.l-basic-smartphone:before {
  content: "\e1cb";
}
.l-basic-spades:before {
  content: "\e1cc";
}
.l-basic-spread:before {
  content: "\e1cd";
}
.l-basic-spread-bookmark:before {
  content: "\e1ce";
}
.l-basic-spread-text:before {
  content: "\e1cf";
}
.l-basic-spread-text-bookmark:before {
  content: "\e1d0";
}
.l-basic-star:before {
  content: "\e1d1";
}
.l-basic-tablet:before {
  content: "\e1d2";
}
.l-basic-target:before {
  content: "\e1d3";
}
.l-basic-todo:before {
  content: "\e1d4";
}
.l-basic-todo-pen:before {
  content: "\e1d5";
}
.l-basic-todo-pencil:before {
  content: "\e1d6";
}
.l-basic-todo-txt:before {
  content: "\e1d7";
}
.l-basic-todolist-pen:before {
  content: "\e1d8";
}
.l-basic-todolist-pencil:before {
  content: "\e1d9";
}
.l-basic-trashcan:before {
  content: "\e1da";
}
.l-basic-trashcan-full:before {
  content: "\e1db";
}
.l-basic-trashcan-refresh:before {
  content: "\e1dc";
}
.l-basic-trashcan-remove:before {
  content: "\e1dd";
}
.l-basic-upload:before {
  content: "\e1de";
}
.l-basic-usb:before {
  content: "\e1df";
}
.l-basic-video:before {
  content: "\e1e0";
}
.l-basic-watch:before {
  content: "\e1e1";
}
.l-basic-webpage:before {
  content: "\e1e2";
}
.l-basic-webpage-img-txt:before {
  content: "\e1e3";
}
.l-basic-webpage-multiple:before {
  content: "\e1e4";
}
.l-basic-webpage-txt:before {
  content: "\e1e5";
}
.l-basic-world:before {
  content: "\e1e6";
}
.l-arrows-anticlockwise:before {
  content: "\e1e7";
}
.l-arrows-anticlockwise-dashed:before {
  content: "\e1e8";
}
.l-arrows-button-down:before {
  content: "\e1e9";
}
.l-arrows-button-off:before {
  content: "\e1ea";
}
.l-arrows-button-on:before {
  content: "\e1eb";
}
.l-arrows-button-up:before {
  content: "\e1ec";
}
.l-arrows-check:before {
  content: "\e1ed";
}
.l-arrows-circle-check:before {
  content: "\e1ee";
}
.l-arrows-circle-down:before {
  content: "\e1ef";
}
.l-arrows-circle-downleft:before {
  content: "\e1f0";
}
.l-arrows-circle-downright:before {
  content: "\e1f1";
}
.l-arrows-circle-left:before {
  content: "\e1f2";
}
.l-arrows-circle-minus:before {
  content: "\e1f3";
}
.l-arrows-circle-plus:before {
  content: "\e1f4";
}
.l-arrows-circle-remove:before {
  content: "\e1f5";
}
.l-arrows-circle-right:before {
  content: "\e1f6";
}
.l-arrows-circle-up:before {
  content: "\e1f7";
}
.l-arrows-circle-upleft:before {
  content: "\e1f8";
}
.l-arrows-circle-upright:before {
  content: "\e1f9";
}
.l-arrows-clockwise:before {
  content: "\e1fa";
}
.l-arrows-clockwise-dashed:before {
  content: "\e1fb";
}
.l-arrows-compress:before {
  content: "\e1fc";
}
.l-arrows-deny:before {
  content: "\e1fd";
}
.l-arrows-diagonal:before {
  content: "\e1fe";
}
.l-arrows-diagonal2:before {
  content: "\e1ff";
}
.l-arrows-down:before {
  content: "\e200";
}
.l-arrows-down-double-34:before {
  content: "\e201";
}
.l-arrows-downleft:before {
  content: "\e202";
}
.l-arrows-downright:before {
  content: "\e203";
}
.l-arrows-drag-down:before {
  content: "\e204";
}
.l-arrows-drag-down-dashed:before {
  content: "\e205";
}
.l-arrows-drag-horiz:before {
  content: "\e206";
}
.l-arrows-drag-left:before {
  content: "\e207";
}
.l-arrows-drag-left-dashed:before {
  content: "\e208";
}
.l-arrows-drag-right:before {
  content: "\e209";
}
.l-arrows-drag-right-dashed:before {
  content: "\e20a";
}
.l-arrows-drag-up:before {
  content: "\e20b";
}
.l-arrows-drag-up-dashed:before {
  content: "\e20c";
}
.l-arrows-drag-vert:before {
  content: "\e20d";
}
.l-arrows-exclamation:before {
  content: "\e20e";
}
.l-arrows-expand:before {
  content: "\e20f";
}
.l-arrows-expand-diagonal1:before {
  content: "\e210";
}
.l-arrows-expand-horizontal1:before {
  content: "\e211";
}
.l-arrows-expand-vertical1:before {
  content: "\e212";
}
.l-arrows-fit-horizontal:before {
  content: "\e213";
}
.l-arrows-fit-vertical:before {
  content: "\e214";
}
.l-arrows-glide:before {
  content: "\e215";
}
.l-arrows-glide-horizontal:before {
  content: "\e216";
}
.l-arrows-glide-vertical:before {
  content: "\e217";
}
.l-arrows-hamburger1:before {
  content: "\e218";
}
.l-arrows-hamburger-2:before {
  content: "\e219";
}
.l-arrows-horizontal:before {
  content: "\e21a";
}
.l-arrows-info:before {
  content: "\e21b";
}
.l-arrows-keyboard-alt:before {
  content: "\e21c";
}
.l-arrows-keyboard-cmd-29:before {
  content: "\e21d";
}
.l-arrows-keyboard-delete:before {
  content: "\e21e";
}
.l-arrows-keyboard-down-28:before {
  content: "\e21f";
}
.l-arrows-keyboard-left:before {
  content: "\e220";
}
.l-arrows-keyboard-return:before {
  content: "\e221";
}
.l-arrows-keyboard-right:before {
  content: "\e222";
}
.l-arrows-keyboard-shift:before {
  content: "\e223";
}
.l-arrows-keyboard-tab:before {
  content: "\e224";
}
.l-arrows-keyboard-up:before {
  content: "\e225";
}
.l-arrows-left:before {
  content: "\e226";
}
.l-arrows-left-double-32:before {
  content: "\e227";
}
.l-arrows-minus:before {
  content: "\e228";
}
.l-arrows-move:before {
  content: "\e229";
}
.l-arrows-move2:before {
  content: "\e22a";
}
.l-arrows-move-bottom:before {
  content: "\e22b";
}
.l-arrows-move-left:before {
  content: "\e22c";
}
.l-arrows-move-right:before {
  content: "\e22d";
}
.l-arrows-move-top:before {
  content: "\e22e";
}
.l-arrows-plus:before {
  content: "\e22f";
}
.l-arrows-question:before {
  content: "\e230";
}
.l-arrows-remove:before {
  content: "\e231";
}
.l-arrows-right:before {
  content: "\e232";
}
.l-arrows-right-double-31:before {
  content: "\e233";
}
.l-arrows-rotate:before {
  content: "\e234";
}
.l-arrows-rotate-anti:before {
  content: "\e235";
}
.l-arrows-rotate-anti-dashed:before {
  content: "\e236";
}
.l-arrows-rotate-dashed:before {
  content: "\e237";
}
.l-arrows-shrink:before {
  content: "\e238";
}
.l-arrows-shrink-diagonal1:before {
  content: "\e239";
}
.l-arrows-shrink-diagonal2:before {
  content: "\e23a";
}
.l-arrows-shrink-horizonal2:before {
  content: "\e23b";
}
.l-arrows-shrink-horizontal1:before {
  content: "\e23c";
}
.l-arrows-shrink-vertical1:before {
  content: "\e23d";
}
.l-arrows-shrink-vertical2:before {
  content: "\e23e";
}
.l-arrows-sign-down:before {
  content: "\e23f";
}
.l-arrows-sign-left:before {
  content: "\e240";
}
.l-arrows-sign-right:before {
  content: "\e241";
}
.l-arrows-sign-up:before {
  content: "\e242";
}
.l-arrows-slide-down1:before {
  content: "\e243";
}
.l-arrows-slide-down2:before {
  content: "\e244";
}
.l-arrows-slide-left1:before {
  content: "\e245";
}
.l-arrows-slide-left2:before {
  content: "\e246";
}
.l-arrows-slide-right1:before {
  content: "\e247";
}
.l-arrows-slide-right2:before {
  content: "\e248";
}
.l-arrows-slide-up1:before {
  content: "\e249";
}
.l-arrows-slide-up2:before {
  content: "\e24a";
}
.l-arrows-slim-down:before {
  content: "\e24b";
}
.l-arrows-slim-down-dashed:before {
  content: "\e24c";
}
.l-arrows-slim-left:before {
  content: "\e24d";
}
.l-arrows-slim-left-dashed:before {
  content: "\e24e";
}
.l-arrows-slim-right:before {
  content: "\e24f";
}
.l-arrows-slim-right-dashed:before {
  content: "\e250";
}
.l-arrows-slim-up:before {
  content: "\e251";
}
.l-arrows-slim-up-dashed:before {
  content: "\e252";
}
.l-arrows-square-check:before {
  content: "\e253";
}
.l-arrows-square-down:before {
  content: "\e254";
}
.l-arrows-square-downleft:before {
  content: "\e255";
}
.l-arrows-square-downright:before {
  content: "\e256";
}
.l-arrows-square-left:before {
  content: "\e257";
}
.l-arrows-square-minus:before {
  content: "\e258";
}
.l-arrows-square-plus:before {
  content: "\e259";
}
.l-arrows-square-remove:before {
  content: "\e25a";
}
.l-arrows-square-right:before {
  content: "\e25b";
}
.l-arrows-square-up:before {
  content: "\e25c";
}
.l-arrows-square-upleft:before {
  content: "\e25d";
}
.l-arrows-square-upright:before {
  content: "\e25e";
}
.l-arrows-squares:before {
  content: "\e25f";
}
.l-arrows-stretch-diagonal1:before {
  content: "\e260";
}
.l-arrows-stretch-diagonal2:before {
  content: "\e261";
}
.l-arrows-stretch-diagonal3:before {
  content: "\e262";
}
.l-arrows-stretch-diagonal4:before {
  content: "\e263";
}
.l-arrows-stretch-horizontal1:before {
  content: "\e264";
}
.l-arrows-stretch-horizontal2:before {
  content: "\e265";
}
.l-arrows-stretch-vertical1:before {
  content: "\e266";
}
.l-arrows-stretch-vertical2:before {
  content: "\e267";
}
.l-arrows-switch-horizontal:before {
  content: "\e268";
}
.l-arrows-switch-vertical:before {
  content: "\e269";
}
.l-arrows-up:before {
  content: "\e26a";
}
.l-arrows-up-double-33:before {
  content: "\e26b";
}
.l-arrows-upleft:before {
  content: "\e26c";
}
.l-arrows-upright:before {
  content: "\e26d";
}
.l-arrows-vertical:before {
  content: "\e26e";
}