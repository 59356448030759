/* You can add global styles to this file, and also import other style files */

@import "@fortawesome/fontawesome-free/css/all.css";
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";

.custom-page-header {
  height: 45px;
}

.h2-header {
  margin-top: 5px;
  margin-bottom: 5px !important;
  font-size: 25px;
  font-weight: 400;
}

.cursor-pointer {
  cursor: pointer;
}

.popover {
  max-width: 700px !important;
}

.multiple-column .ui-autocomplete-panel {
  width: 89% !important;
}

.ui-autocomplete {
  width: 100% !important;
}

.ui-autocomplete button.ui-button {
  color: rgb(85, 85, 85);
  border: rgb(214, 214, 214) 1px solid;
  background-color: transparent;
  height: 34px;
}

.ui-autocomplete input {
  height: 34px;
  width: 89%;
}

.ui-autocomplete input:disabled {
  background-color: #EEE;
  opacity: 1;
  cursor: not-allowed !important;
  border: 1px solid #cacaca;
}

.GC {
  /*TODO: criar ícone para gasolina comum*/
  background-color: #d9534f;
}

.GA {
  /*TODO: criar ícone para gasolina aditivada*/
  background-color: #337ab7;
}

.E {
  /*TODO: criar ícone para etanol*/
  background-color: #5cb85c;
}

.D10 {
  /*TODO: criar ícone para diesel s10*/
  background-color: #777;
}

.D500 {
  /*TODO: criar ícone para diesel s500*/
  background-color: #222;
}

.swal2-popup {
  font-size: 1.6rem !important;
}

.glyphicon-ok {
  margin-right: 5px !important;
  margin-left: -5px !important;
}

.d-inline-block {
  display: inline-block;
}

.d-flex {
  display: flex;
}

.d-none {
  display: none;
}

input[type="radio"][disabled] {
  cursor: not-allowed;
}

input[type="radio"][disabled]+label {
  cursor: not-allowed;
}

div.radio-custom input[type=radio]:disabled+label:before {
  background-color: rgb(238 238 238);
}

@media(max-width: 1024px) {

  .page-content.clearfix.collapsed-sidebar,
  .page-content.clearfix.sidebar-page {
    margin-left: 0px;
  }

  .page-sidebar.collapse-sidebar {
    display: none;
  }
}

@media(max-width: 767px) {
  .h2-header {
    font-size: 18px;
  }
}

/*
.escondido {
  animation: delay-esconder 300ms;
}
@keyframes delay-esconder {
  0% {
    height: 42px;
  }
  99% {
    height: 42px;
  }

  100% {
    height: auto;
  }
}*/

.page-content {
  padding-left: 0px;
}

table>thead>tr {
  background: #e8e8e8;
  color: #333333;
}

.flex-align-right {
  display: flex;
  justify-content: flex-end;
}

span.p-calendar > input {
  width: 100%;
  height: 36px;
}


.dropdown-menu .dropdown-item {
  padding: 0;
  border-radius: 3px;
}

  .dropdown-menu .dropdown-item {
    padding: 8px 20px;
    color: #333333;
    transition: all;
    transition-duration: 0.4s;
  }

    .dropdown-menu .dropdown-item i {
      margin-right: 10px;
      font-size: 14px;
    }

.dropdown-menu:after,
.dropdown-menu:before {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.dropdown-menu:after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #fff;
  border-width: 10px;
  margin-left: -10px;
}

.dropdown-menu:before {
  border-bottom-color: #efefef;
  border-width: 11px;
  margin-left: -12px;
}

.dropdown-menu.right {
  right: 0;
  left: auto;
}

  .dropdown-menu.right:before {
    left: auto !important;
    right: 17px;
  }

  .dropdown-menu.right:after {
    left: auto !important;
    right: 18px;
  }





.dropdown-menu .dropdown-item {
  display: block;
  padding: 3px 8px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  color: #333333;
  white-space: nowrap;
}


.dropdown-menu .dropdown-item.uncheck-all {
  border-bottom: 1px solid #ddd !important;
  padding-bottom: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.ss-multiselect-dropdown .dropdown-divider {
  margin: 1px  0; /* Reduz a margem padrão */
  border-color: #ddd; /* Ajusta a cor da linha divisória */
}

.ss-multiselect-dropdown .dropdown-item.uncheck-all {
  border-bottom: 1px solid #ddd !important;
  padding-bottom: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.dropdown-menu .divider {
  margin: 1px 0;
}

.dropdown-menu .dropdown-item.divider {
  padding: 0;
}